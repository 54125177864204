import {
    DataUsageAllowance,
    DeviceActionRequestFilter,
    DeviceApiGetDevicesRequest,
    Markup,
    PostProcessingTiers,
    SimCardActionRequestFilter,
    SimcardApiGetSimCardsRequest,
    SmsUsageAllowance,
    TelcoRate,
} from "../api";
import { PricingOption, TelcoRateFormSchemaType } from "../forms/TelcoRateForm/TelcoRateFormSchema";
import { mapMarkup } from "./markupUtils";
import { CreateUsagePackageTypeFormSchemaType } from "../forms/CreateUsagePackageTypeForm/CreateUsagePackageTypeForm";

export const mapFromDeviceActionFilterToSearchFilter = (
    pageNumber: number,
    pageSize: number,
    filter?: DeviceActionRequestFilter,
): DeviceApiGetDevicesRequest => ({
    pageNumber: pageNumber,
    pageSize: pageSize,
    deviceConnectionState: filter?.deviceConnectionState || undefined,
    icc: filter?.icc || undefined,
    identifier: filter?.identifier || undefined,
    search: filter?.search || undefined,
    model: filter?.model || undefined,
});

export const mapFromActionFilterToSearchFilter = (
    pageNumber: number,
    pageSize: number,
    filter?: SimCardActionRequestFilter,
): SimcardApiGetSimCardsRequest => ({
    pageNumber: pageNumber,
    pageSize: pageSize,
    simConfigurationSearch: filter?.simConfigurationId || undefined,
    networkState: filter?.networkState || undefined,
    iccUpperBound: filter?.iccUpperBound || undefined,
    iccLowerBound: filter?.iccLowerBound || undefined,
    billingGroupIdSet: filter?.billingGroupIdSet || undefined,
    customerId: filter?.customerId || undefined,
    search: filter?.search || undefined,
    consumptionState: filter?.consumptionState || undefined,
    plmnSearch: filter?.plmnSearch || undefined,
    isMultiImsi: filter?.isMultiImsi || undefined,
    networkCredentialTypeSearch: filter?.networkCredentialTypeId || undefined,
    simCapability: filter?.simCapability || undefined,
    country: filter?.country || undefined,
    usagePackageTypeSearch: filter?.usagePackageTypeId || undefined,
});

export const mapFromSearchFilterToActionFilter = (
    filter?: SimcardApiGetSimCardsRequest,
): SimCardActionRequestFilter => ({
    simConfigurationId: filter?.simConfigurationSearch || undefined,
    networkState: filter?.networkState || undefined,
    iccUpperBound: filter?.iccUpperBound || undefined,
    iccLowerBound: filter?.iccLowerBound || undefined,
    billingGroupIdSet: filter?.billingGroupIdSet || undefined,
    customerId: filter?.customerId || undefined,
    search: filter?.search || undefined,
    consumptionState: filter?.consumptionState || undefined,
    plmnSearch: filter?.plmnSearch || undefined,
    isMultiImsi: filter?.isMultiImsi || undefined,
    networkCredentialTypeId: filter?.networkCredentialTypeSearch || undefined,
    simCapability: filter?.simCapability || undefined,
    country: filter?.country || undefined,
    usagePackageTypeId: filter?.usagePackageTypeSearch || undefined,
});

const mapPostProcessingTiers = ({
    pricingStrategy,
    postProcessing,
    absoluteMarkupConversionRate,
}: TelcoRateFormSchemaType): PostProcessingTiers | undefined => {
    if (pricingStrategy === PricingOption.ADVANCED && postProcessing) {
        const postProcessedTiers = postProcessing.discountTiers;
        return {
            context: postProcessing.context,
            strategy: postProcessing.strategy,
            tiers: postProcessedTiers.bounds.map((bound, index) => ({
                markup: mapMarkup(postProcessedTiers.markup, bound.markupAmount, absoluteMarkupConversionRate),
                lowerBound: index > 0 ? (postProcessedTiers?.bounds[index - 1]?.upperBound || 0) + 1 : 0,
                upperBound: bound.upperBound,
            })),
        };
    }
    return undefined;
};

const mapTelcoRateTiers = ({
    pricingStrategy,
    rate,
    absoluteMarkupConversionRate,
}: TelcoRateFormSchemaType): Markup => {
    if (pricingStrategy === PricingOption.SIMPLE && rate !== undefined) {
        return mapMarkup(rate?.markup, rate?.markupAmount, absoluteMarkupConversionRate);
    }
    return {
        percentageMarkup: 0,
    };
};

export const mapToTelcoRate = (formRate: TelcoRateFormSchemaType): TelcoRate => {
    return {
        rateDiscriminator: formRate.rateDiscriminator,
        rateKey: new Set([formRate.rateKey]),
        currency: formRate.currency,
        rateMarkup: mapTelcoRateTiers(formRate),
        postProcessedTiers: mapPostProcessingTiers(formRate),
    };
};

export const mapDataAllowance = (
    dataBundle: CreateUsagePackageTypeFormSchemaType["dataBundle"],
): DataUsageAllowance => {
    if (dataBundle.enabled) {
        return {
            enabled: true,
            dataAllowance: dataBundle.allowance,
        };
    }

    return {
        enabled: true,
        dataAllowance: 0,
    };
};

export const mapSmsAllowance = (smsBundle: CreateUsagePackageTypeFormSchemaType["smsBundle"]): SmsUsageAllowance => {
    if (smsBundle.enabled) {
        return {
            enabled: true,
            smsAllowance: smsBundle.allowance,
        };
    }

    return {
        enabled: true,
        smsAllowance: 0,
    };
};

export const mapBooleanFilter = (value: boolean | null | undefined) => {
    if (value === null || value === undefined) {
        return undefined;
    } else {
        return value;
    }
};
