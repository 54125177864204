import React, { useState } from "react";
import {
    CobiraTable,
    Duration,
    Page,
    PageTitle,
    PlaceholderText,
    SearchBar,
    SearchSelectInput,
    SearchSingleInput,
    singleInput,
    usePageNavigation,
    usePersistedSearch,
    useUrlPagination,
} from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { Device, DeviceActionRequestFilter, DeviceConnectionState } from "../../../api";
import { useQuery } from "@tanstack/react-query";
import { VStack } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { DeviceConnectionStateLabels } from "../../../labels/DeviceConnectionStateLabels";
import DeviceActionModals, { DeviceActionType } from "../../../components/Modal/DeviceActionModals/DeviceActionModals";
import { filterHasValues } from "../../../utils/filterUtils";
import DeviceConnectionStatePopover from "../../../components/Device/DeviceConnectionStatePopover/DeviceConnectionStatePopover";
import { Permission } from "../../../utils/Permissions";
import RestrictedPage from "../../RestrictedPage/RestrictedPage";

const customerColumn = createColumnHelper<Device>();
const COLUMNS = [
    customerColumn.accessor(row => row, {
        id: "connectionState",
        header: "Connected",
        cell: row => (
            <DeviceConnectionStatePopover
                connectionState={row.getValue().connectionState}
                lastConnectedAt={row.getValue().lastConnectedAt}
            />
        ),
    }),
    customerColumn.accessor("identifier", {
        id: "identifier",
        header: "Identifier",
        cell: row => row.getValue(),
    }),
    customerColumn.accessor("name", {
        id: "name",
        header: "Name",
        cell: row => row.getValue(),
    }),
    customerColumn.accessor("icc", {
        id: "icc",
        header: "SIM ICC",
        cell: row => <PlaceholderText text={row.getValue()} />,
    }),
    customerColumn.accessor("firmware", {
        id: "firmware",
        header: "Firmware",
        cell: row => <PlaceholderText text={row.getValue()} />,
    }),
    customerColumn.accessor("model", {
        id: "model",
        header: "Model",
        cell: row => <PlaceholderText text={row.getValue()} />,
    }),
    customerColumn.accessor("deviceUptime", {
        id: "deviceUptime",
        header: "Device Uptime",
        cell: row => <Duration value={row.getValue()} />,
    }),
    customerColumn.accessor("mobileConnectionUptime", {
        id: "mobileConnectionUptime",
        header: "Modem Uptime",
        cell: row => <Duration value={row.getValue()} />,
    }),
];

const DeviceListPage = () => {
    const { deviceApi } = useApi();
    const { navigate } = usePageNavigation<Device>({ route: value => `/devices/${value.id}` });
    const [selectedDeviceIds, setSelectedDeviceIds] = useState<string[]>([]);
    const [chosenAction, setChosenAction] = useState<DeviceActionType | null>(null);
    const { pageState, setPageState, resetPageState } = useUrlPagination({
        pageIndex: 0,
        pageSize: 20,
    });
    const search = usePersistedSearch({
        persistenceKey: "device-list-search",
        config: {
            search: singleInput<string>("Search"),
            model: singleInput<string>("Model"),
            identifier: singleInput<string>("Identifier"),
            icc: singleInput<string>("ICC"),
            deviceState: singleInput<DeviceConnectionState>("Device State"),
        },
        onChange: resetPageState,
    });
    const { state } = search;

    const actionFilter: DeviceActionRequestFilter = {
        deviceIdSet: selectedDeviceIds.length ? new Set<string>(selectedDeviceIds) : undefined,
        icc: search?.state?.icc?.value || undefined,
        deviceConnectionState: search?.state?.deviceState?.value || undefined,
        identifier: search?.state?.identifier?.value || undefined,
        search: search?.state?.search?.value || undefined,
        model: search?.state?.model?.value || undefined,
    };

    const { data: devices, isLoading } = useQuery(["devices", pageState, state], () => {
        return deviceApi.getDevices({
            pageNumber: pageState.pageIndex,
            pageSize: pageState.pageSize,
            icc: state?.icc?.value || undefined,
            identifier: state?.identifier?.value || undefined,
            deviceConnectionState: state?.deviceState?.value || undefined,
            search: search?.state?.search?.value || undefined,
            model: search?.state?.model?.value || undefined,
        });
    });

    return (
        <>
            <Page
                title={<PageTitle title="Device Inventory" />}
                permissions={{
                    values: [Permission.device.read],
                    deniedComponent: <RestrictedPage />,
                }}
            >
                <VStack w={"100%"} gap={2}>
                    <SearchBar
                        defaultFilterInputId={"search"}
                        useSearch={search}
                        filterInputs={[
                            {
                                id: "search",
                                menuLabel: "Search",
                                inputComponent: (
                                    <SearchSingleInput
                                        registration={search.registerInput({ id: "search" })}
                                        placeholder={"Search by name & identifier"}
                                    />
                                ),
                            },
                            {
                                id: "identifier",
                                menuLabel: "Identifier",
                                inputComponent: (
                                    <SearchSingleInput
                                        registration={search.registerInput({ id: "identifier" })}
                                        placeholder={"Search by Device Identifier"}
                                    />
                                ),
                            },
                            {
                                id: "model",
                                menuLabel: "Model",
                                inputComponent: (
                                    <SearchSingleInput
                                        registration={search.registerInput({ id: "model" })}
                                        placeholder={"Search by Device Model"}
                                    />
                                ),
                            },
                            {
                                id: "icc",
                                menuLabel: "ICC",
                                inputComponent: (
                                    <SearchSingleInput
                                        registration={search.registerInput({ id: "icc" })}
                                        placeholder={"Search by ICC"}
                                    />
                                ),
                            },
                            {
                                id: "deviceState",
                                menuLabel: "Device State",
                                inputComponent: (
                                    <SearchSelectInput
                                        registration={search.registerInput({ id: "deviceState" })}
                                        placeholder={"Select Device State"}
                                        displayMapping={value => DeviceConnectionStateLabels[value]}
                                        items={[DeviceConnectionState.Connected, DeviceConnectionState.Disconnected]}
                                        autocompleteAbleMapping={value => DeviceConnectionStateLabels[value]}
                                    />
                                ),
                            },
                        ]}
                        actions={{
                            enabled: selectedDeviceIds.length > 0 || filterHasValues(actionFilter),
                            entries: [
                                {
                                    id: "reboot",
                                    label: "Reboot",
                                    onClick: () => setChosenAction("reboot"),
                                    permissions: {
                                        values: [Permission.device.write],
                                    },
                                },
                                {
                                    id: "discoverHosts",
                                    label: "Refresh Host Table",
                                    onClick: () => setChosenAction("discover-host"),
                                    permissions: {
                                        values: [Permission.device.write],
                                    },
                                },
                            ],
                        }}
                    />
                    <CobiraTable
                        sizing={"fit-page"}
                        columns={COLUMNS}
                        data={devices?.content || []}
                        isLoading={isLoading}
                        withPagination={{
                            pageIndex: pageState.pageIndex,
                            pageSize: pageState.pageSize,
                            onPaginationChange: setPageState,
                            totalRowCount: devices?.pageProperties?.totalElements || 0,
                            totalPageCount: devices?.pageProperties?.totalPages || 0,
                        }}
                        withRowSelection={{
                            rowId: row => row.id,
                            onChange: setSelectedDeviceIds,
                        }}
                        withRowClick={{
                            enableHoverStyle: true,
                            onRowClicked: navigate,
                        }}
                    />
                </VStack>
            </Page>
            <DeviceActionModals actionType={chosenAction} filter={actionFilter} onClose={() => setChosenAction(null)} />
        </>
    );
};

export default DeviceListPage;
