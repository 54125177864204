import React from "react";
import { SimCard } from "../../../../api";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import DataUsageTable from "../../../../components/DataUsageTable/DataUsageTable";
import { TabPanel } from "@chakra-ui/react";

interface SimCardDataSessionsPanelProps {
    query: UseQueryResult<SimCard>;
}

const SimCardDataSessionsPanel = ({ query: { data: simCard } }: SimCardDataSessionsPanelProps) => {
    return (
        <TabPanel w={"100%"}>
            <DataUsageTable tableKey={"sim-card-data-usage-table"} simCard={simCard} />
        </TabPanel>
    );
};

export default SimCardDataSessionsPanel;
