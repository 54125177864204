import { SimCard } from "../../../../api";
import { Grid, Heading, HStack } from "@chakra-ui/react";
import { IconLink, NotFound, Paper, PlaceholderText, TextColumn } from "@cobira/ui-library";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../../../hooks/useApi";
import { CustomerStateLabels } from "../../../../labels/CustomerStateLabels";
import { CustomersIcon } from "../../../../components/Icons/CustomersIcon/CustomersIcon";

export interface SimCardCustomerPaperProps {
    simCard?: SimCard;
    isLoading?: boolean;
}

const SimCardCustomerPaper = ({ simCard, isLoading }: SimCardCustomerPaperProps) => {
    const { customerApi, billingGroupApi } = useApi();

    const { data: billingGroup, isLoading: loadingBillingGroup } = useQuery(
        ["billinggroups", simCard?.billingGroupId],
        () => billingGroupApi.getBillingGroup({ billingGroupId: simCard?.billingGroupId || "" }),
        { enabled: !!simCard?.billingGroupId },
    );
    const { data: customer, isLoading: loadingCustomer } = useQuery(
        ["customers", billingGroup?.customerId],
        () => customerApi.getCustomer({ customerId: billingGroup?.customerId || "" }),
        { enabled: !!billingGroup?.customerId },
    );

    if (!isLoading && !simCard?.billingGroupId) {
        return (
            <Paper header={"Customer & Billing Group"} withDivider>
                <NotFound icon={CustomersIcon} resource={"Assignment"} withIcon />
            </Paper>
        );
    }

    return (
        <Paper header={"Customer & Billing Group"} withDivider>
            <Grid templateColumns="auto auto" gap="4" rowGap={2}>
                <TextColumn
                    heading={
                        <HStack w={"100%"} gap={2}>
                            <Heading size={"sm"}>Customer</Heading>
                            <IconLink path={`/customers/${customer?.id}`} mt={"-0.35em"} />
                        </HStack>
                    }
                    grid
                    isLoading={isLoading}
                >
                    <PlaceholderText text={customer?.customerName} />
                </TextColumn>
                <TextColumn heading="Customer State" grid isLoading={loadingCustomer}>
                    <PlaceholderText text={customer?.state && CustomerStateLabels[customer?.state]} />
                </TextColumn>
                <TextColumn
                    heading={
                        <HStack w={"100%"} gap={2}>
                            <Heading size={"sm"}>Billing Group</Heading>
                            <IconLink
                                path={`/customers/${customer?.id}/billinggroups/${billingGroup?.id}`}
                                mt={"-0.35em"}
                            />
                        </HStack>
                    }
                    grid
                    isLoading={loadingBillingGroup}
                >
                    <PlaceholderText text={billingGroup?.name} />
                </TextColumn>
            </Grid>
        </Paper>
    );
};

export default SimCardCustomerPaper;
