import React from "react";
import { TabPanel } from "@chakra-ui/react";
import { Action as MvnoAction, Device } from "../../../../api";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { Action, ActionTable, Loading, NotFound, useUrlPagination } from "@cobira/ui-library";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../../../hooks/useApi";
import { ActionTypeLabels } from "../../../../labels/ActionTypeLabels";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import useActionActor from "../../../../hooks/useActionActor";

export interface DeviceLanHostsPanelProps {
    deviceQuery: UseQueryResult<Device>;
}

const DeviceLanHostsPanel = ({ deviceQuery: { data: device, isLoading } }: DeviceLanHostsPanelProps) => {
    const { actionApi } = useApi();
    const actionActor = useActionActor();
    const { pageState, setPageState } = useUrlPagination({
        pageIndex: 0,
        pageSize: 20,
    });
    const { data: actions, isLoading: isLoadingActions } = useQuery(["deviceactions", pageState, device?.id], () =>
        actionApi.getActions({
            pageNumber: pageState.pageIndex,
            pageSize: pageState.pageSize,
            itemSearch: device?.id || undefined,
        }),
    );
    const actionColumnHelper = createColumnHelper<MvnoAction>();

    if (isLoading) {
        return (
            <TabPanel w={"100%"}>
                <Loading />
            </TabPanel>
        );
    }

    if (!device) {
        return (
            <TabPanel>
                <NotFound resource={"Device"} />
            </TabPanel>
        );
    }

    return (
        <TabPanel>
            <ActionTable
                data={actions?.content || []}
                withPagination={{
                    pageSize: pageState.pageSize,
                    pageIndex: pageState.pageIndex,
                    onPaginationChange: setPageState,
                    totalRowCount: actions?.pageProperties?.totalElements || 0,
                    totalPageCount: actions?.pageProperties?.totalPages || 0,
                }}
                isLoading={isLoadingActions}
                actionTypeColumn={
                    actionColumnHelper.accessor("actionType", {
                        id: "actionType",
                        header: "Type",
                        cell: row => ActionTypeLabels[row.getValue() || "UNKNOWN"],
                    }) as ColumnDef<Action>
                }
                actionActorResolvers={actionActor}
            />
        </TabPanel>
    );
};

export default DeviceLanHostsPanel;
