import React from "react";
import {
    Action,
    ActionStateLabel,
    ActionTable,
    PageLayout,
    PageTitle,
    SearchBar,
    SearchSelectInput,
    SearchSingleInput,
    singleInput,
    usePageNavigation,
    usePersistedSearch,
    useUrlPagination,
} from "@cobira/ui-library";
import { useQuery } from "@tanstack/react-query";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useApi } from "../../../hooks/useApi";
import { Action as MvnoAction, ActionState, ActionType as ActionTypeEnum } from "../../../api";
import { ActionTypeLabels } from "../../../labels/ActionTypeLabels";
import useActionActor from "../../../hooks/useActionActor";
import {
    ActionActorSelect,
    SearchSelectActionActorInput,
} from "../../../components/SearchSelectActionActorInput/SearchSelectActionActorInput";

const SimInventoryActionTypes = [
    ActionTypeEnum.AssignSimCard,
    ActionTypeEnum.NetworkActivateSimCard,
    ActionTypeEnum.NetworkResetSimCard,
    ActionTypeEnum.NetworkSuspendSimCard,
    ActionTypeEnum.NetworkTerminateSimCard,
    ActionTypeEnum.NetworkManageUsagePackageAction,
    ActionTypeEnum.NetworkSetImeiLockState,
    ActionTypeEnum.NetworkUpdateImeiLock,
    ActionTypeEnum.NetworkManageSimCapabilityAction,
    ActionTypeEnum.NetworkSetSimProvisioningState,
    ActionTypeEnum.NetworkSetOverage,
    ActionTypeEnum.NetworkUsagePackageTypeCreate,
];

const MvnoActionListPage = () => {
    const { actionApi } = useApi();

    const { navigate } = usePageNavigation<Action>({ route: value => `/actions/${value.id}` });

    const { pageState, setPageState, resetPageState } = useUrlPagination({
        pageIndex: 0,
        pageSize: 20,
    });
    const search = usePersistedSearch({
        persistenceKey: "action-table-search",
        config: {
            correlationId: singleInput<string>("Correlation ID"),
            actionState: singleInput<ActionState>("Action State", { display: value => ActionStateLabel[value] }),
            actionType: singleInput<ActionTypeEnum>("Type of action", { display: value => ActionTypeLabels[value] }),
            actionActor: singleInput<ActionActorSelect>("Action Actor", { display: value => value.name }),
            itemSearch: singleInput<string>("Item Search"),
        },
        onChange: resetPageState,
    });

    const { state } = search;
    const actionColumnHelper = createColumnHelper<MvnoAction>();

    const { data: actions, isLoading } = useQuery(["actions", pageState, state], () =>
        actionApi.getActions({
            pageNumber: pageState.pageIndex,
            pageSize: pageState.pageSize,
            correlationId: state?.correlationId?.value || undefined,
            actionState: state?.actionState?.value ? [state?.actionState?.value] : undefined,
            actionType: state?.actionType?.value
                ? new Set([state?.actionType?.value])
                : new Set(SimInventoryActionTypes),
            actionActor: state?.actionActor?.value?.id || undefined,
            itemSearch: state?.itemSearch?.value || undefined,
        }),
    );

    const actionActor = useActionActor();

    return (
        <PageLayout title={<PageTitle title={"Activity"} />}>
            <SearchBar
                defaultFilterInputId={"correlationId"}
                useSearch={search}
                filterInputs={[
                    {
                        id: "correlationId",
                        menuLabel: "Correlation ID",
                        inputComponent: (
                            <SearchSingleInput
                                registration={search.registerInput({ id: "correlationId" })}
                                placeholder={"Correlation ID"}
                            />
                        ),
                    },
                    {
                        id: "actionStates",
                        menuLabel: "Action State",
                        inputComponent: (
                            <SearchSelectInput
                                registration={search.registerInput({ id: "actionState" })}
                                items={Object.values(ActionState)}
                                placeholder={"Select State"}
                                autocompleteAbleMapping={state => ActionStateLabel[state as ActionState]}
                                displayMapping={state => ActionStateLabel[state as ActionState]}
                            />
                        ),
                    },
                    {
                        id: "actionType",
                        menuLabel: "Action Type",
                        inputComponent: (
                            <SearchSelectInput
                                registration={search.registerInput({ id: "actionType" })}
                                items={Object.values(SimInventoryActionTypes)}
                                placeholder={"Select Type"}
                                autocompleteAbleMapping={state => ActionTypeLabels[state as ActionTypeEnum]}
                                displayMapping={state => ActionTypeLabels[state as ActionTypeEnum]}
                            />
                        ),
                    },
                    {
                        id: "itemSearch",
                        menuLabel: "Item Search",
                        inputComponent: (
                            <SearchSingleInput
                                registration={search.registerInput({ id: "itemSearch" })}
                                placeholder={"Search for items"}
                            />
                        ),
                    },
                    {
                        id: "actionActor",
                        menuLabel: "Action Actor",
                        inputComponent: (
                            <SearchSelectActionActorInput registration={search.registerInput({ id: "actionActor" })} />
                        ),
                    },
                ]}
            />
            <ActionTable
                sizing={"fit-page"}
                data={actions?.content || []}
                withRowClick={{
                    enableHoverStyle: true,
                    onRowClicked: navigate,
                }}
                withPagination={{
                    pageSize: pageState.pageSize,
                    pageIndex: pageState.pageIndex,
                    onPaginationChange: setPageState,
                    totalRowCount: actions?.pageProperties?.totalElements || 0,
                    totalPageCount: actions?.pageProperties?.totalPages || 0,
                }}
                isLoading={isLoading}
                actionTypeColumn={
                    actionColumnHelper.accessor("actionType", {
                        id: "actionType",
                        header: "Type",
                        cell: row => ActionTypeLabels[row.getValue() || "UNKNOWN"],
                    }) as ColumnDef<Action>
                }
                actionActorResolvers={actionActor}
            />
        </PageLayout>
    );
};

export default MvnoActionListPage;
