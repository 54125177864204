import React, { useMemo, useState } from "react";
import { ChevronDownIcon, CobiraTabs, PageBackButton, PageLayout, PageTitle } from "@cobira/ui-library";
import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import SimCardDetailPanel from "./SimCardDetailPanel/SimCardDetailPanel";
import SimCardDataSessionsPanel from "./SimCardDataSessionsPanel/SimCardDataSessionsPanel";
import SimCardLocationUpdatesPanel from "./SimCardLocationUpdatesPanel/SimCardLocationUpdatesPanel";
import SimCardUsagePackagesPanel from "./SimCardUsagePackagesPanel/SimCardUsagePackagesPanel";
import SimCardNetworkCredentialsPanel from "./SimCardNetworkCredentialsPanel/SimCardNetworkCredentialsPanel";
import { useApi } from "../../../hooks/useApi";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { SimCardActionRequestFilter } from "../../../api";
import { HardwareTabPanel } from "./HardwareTabPanel/HardwareTabPanel";
import SimCardSmsUsagePanel from "./SimCardSmsUsagePanel/SimCardSmsUsagePanel";
import { tab } from "../../../utils/tabUtils";
import SimCardActionModals, { SimActionType } from "../../../components/Modal/SimCardActionModals/SimCardActionModals";

const SimCardDetailPage = () => {
    const { simCardApi, deviceApi } = useApi();
    const { icc } = useParams();
    const [chosenAction, setChosenAction] = useState<SimActionType | null>(null);

    const simQuery = useQuery(["simcards", icc], () => simCardApi.getSimCard({ icc: icc || "" }), {
        enabled: icc != undefined,
    });

    const { data: device } = useQuery(
        ["simcards", icc, "device"],
        () => deviceApi.getDevices({ pageNumber: 0, pageSize: 1, icc: icc }).then(response => response?.content?.at(0)),
        {
            enabled: !!icc,
        },
    );

    const hardwareTabEnabled = useMemo(() => !!device, [device]);

    const filter: SimCardActionRequestFilter = {
        iccSet: new Set<string>([icc as string]),
    };

    const simCardDetailTabs = [
        tab("Details", <SimCardDetailPanel query={simQuery} />),
        tab("Data Usage", <SimCardDataSessionsPanel query={simQuery} />),
        tab("SMS Usage", <SimCardSmsUsagePanel query={simQuery} />),
        tab("Location Updates", <SimCardLocationUpdatesPanel query={simQuery} />),
        tab("Bundles", <SimCardUsagePackagesPanel query={simQuery} />),
        tab("Network Credentials", <SimCardNetworkCredentialsPanel query={simQuery} />),
        ...(hardwareTabEnabled ? [tab("Device", <HardwareTabPanel query={simQuery} />)] : []),
    ];

    return (
        <PageLayout
            title={<PageTitle title={"SIM Card"} />}
            navigation={<PageBackButton text={"Back to inventory"} path={"/simcards"} />}
            pageContext={
                <Menu>
                    <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                        Manage
                    </MenuButton>
                    <MenuList>
                        <MenuItem onClick={() => setChosenAction("activate")}>Activate</MenuItem>
                        <MenuItem onClick={() => setChosenAction("suspend")}>Suspend</MenuItem>
                        <MenuItem onClick={() => setChosenAction("reset")}>Reset</MenuItem>
                        <MenuItem onClick={() => setChosenAction("assign-usage-package")}>Assign Bundle</MenuItem>
                        <MenuItem onClick={() => setChosenAction("revoke-usage-package")}>Revoke Bundle</MenuItem>
                        <MenuItem onClick={() => setChosenAction("imei-lock")}>Set IMEI Lock</MenuItem>
                        <MenuItem onClick={() => setChosenAction("imei-lock-state")}>Set IMEI Lock Behaviour</MenuItem>
                        <MenuItem onClick={() => setChosenAction("send-sms")}>Send SMS</MenuItem>
                        <MenuItem onClick={() => setChosenAction("move-sim-cards")}>Move SIM Card</MenuItem>
                        <MenuItem onClick={() => setChosenAction("set-overage-state")}>Set Overage State</MenuItem>
                    </MenuList>
                </Menu>
            }
        >
            <CobiraTabs tabs={simCardDetailTabs} isLazy />

            <SimCardActionModals actionType={chosenAction} filter={filter} onClose={() => setChosenAction(null)} />
        </PageLayout>
    );
};

export default SimCardDetailPage;
