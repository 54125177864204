import React from "react";
import { SimCardNetworkState, SimCardNetworkStateCellularPosition } from "../../../api";
import { MapLocationPaper } from "@cobira/ui-library";

const toMapPosition = (cellularPosition?: SimCardNetworkStateCellularPosition) => {
    if (cellularPosition && cellularPosition.latitude !== undefined && cellularPosition.longitude !== undefined) {
        return {
            latitude: cellularPosition.latitude,
            longitude: cellularPosition.longitude,
        };
    }
    return undefined;
};

const isMapLocationStale = (networkState?: SimCardNetworkState) => {
    if (networkState && networkState.cellularPosition.recordedAt && networkState.cell.recordedAt) {
        return networkState.cell.recordedAt > networkState.cellularPosition.recordedAt;
    } else {
        return false;
    }
};

export interface NetworkStateMapLocationProps {
    networkState?: SimCardNetworkState;
}

const NetworkStateMapLocation = ({ networkState }: NetworkStateMapLocationProps) => {
    return (
        <MapLocationPaper
            header={"Approximate Location"}
            accuracy={1000}
            position={toMapPosition(networkState?.cellularPosition)}
            zoom={13}
            markerIcon={"cell-tower"}
            cardProps={{
                height: "100%",
            }}
            state={isMapLocationStale(networkState) ? "STALE" : "OK"}
            updatedAt={networkState?.cellularPosition.recordedAt}
        />
    );
};

export default NetworkStateMapLocation;
