import React, { ReactNode } from "react";
import { SimCardNetworkState } from "../../../api";
import { PlaceholderText } from "@cobira/ui-library";

interface NetworkStateCellIdProps {
    networkState?: SimCardNetworkState;
    placeholderComponent?: ReactNode;
}

export const NetworkStateCellId = ({ networkState, placeholderComponent }: NetworkStateCellIdProps) => {
    if (!networkState?.cell) {
        return <>{placeholderComponent}</>;
    }

    return <PlaceholderText text={networkState?.cell?.cellId?.toString()} />;
};
