/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Information gathered from the attached device regarding the quality of its connection to the cellular network
 * @export
 * @interface SimCardNetworkStateSignalCondition
 */
export interface SimCardNetworkStateSignalCondition {
    /**
     * 
     * @type {number}
     * @memberof SimCardNetworkStateSignalCondition
     */
    signalStrength?: number;
    /**
     * 
     * @type {number}
     * @memberof SimCardNetworkStateSignalCondition
     */
    signalQuality?: number;
    /**
     * 
     * @type {Date}
     * @memberof SimCardNetworkStateSignalCondition
     */
    recordedAt?: Date;
}

/**
 * Check if a given object implements the SimCardNetworkStateSignalCondition interface.
 */
export function instanceOfSimCardNetworkStateSignalCondition(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SimCardNetworkStateSignalConditionFromJSON(json: any): SimCardNetworkStateSignalCondition {
    return SimCardNetworkStateSignalConditionFromJSONTyped(json, false);
}

export function SimCardNetworkStateSignalConditionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimCardNetworkStateSignalCondition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'signalStrength': !exists(json, 'signalStrength') ? undefined : json['signalStrength'],
        'signalQuality': !exists(json, 'signalQuality') ? undefined : json['signalQuality'],
        'recordedAt': !exists(json, 'recordedAt') ? undefined : (new Date(json['recordedAt'])),
    };
}

export function SimCardNetworkStateSignalConditionToJSON(value?: SimCardNetworkStateSignalCondition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'signalStrength': value.signalStrength,
        'signalQuality': value.signalQuality,
        'recordedAt': value.recordedAt === undefined ? undefined : (value.recordedAt.toISOString()),
    };
}

