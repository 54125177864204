import React from "react";
import { Grid, GridItem, Heading, HStack, TabPanel, VStack } from "@chakra-ui/react";
import { Device, SimCard, SimCardNetworkState } from "../../../../api";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import DeviceInfoCards from "../../../../components/Device/DeviceInfoCards/DeviceInfoCards";
import DeviceHardwareDetailsPaper from "../../../../components/Device/DeviceHardwareDetailsPaper/DeviceHardwareDetailsPaper";
import DeviceModemDetailsPaper from "../../../../components/Device/DeviceModemDetailsPaper/DeviceModemDetailsPaper";
import NetworkStateMapLocation from "../../../../components/NetworkState/NetworkStateMapLocation/NetworkStateMapLocation";
import { CopyButton, IconLink, Loading, NotFound, Paper, PlaceholderText, TextColumn } from "@cobira/ui-library";
import NetworkStateSummaryPaper from "../../../../components/NetworkState/NetworkStateSummary/NetworkStateSummaryPaper";
import { SimCardStateCell } from "../../../../components/Cells/SimCardStateCell/SimCardStateCell";
import NetworkStateCircuitSwitchingState from "../../../../components/NetworkState/NetworkStateCircuitSwitchingState/NetworkStateCircuitSwitchingState";
import NetworkStatePacketSwitchingState from "../../../../components/NetworkState/NetworkStatePacketSwitchingState/NetworkStatePacketSwitchingState";

export interface DeviceDetailsPanelProps {
    deviceQuery: UseQueryResult<Device>;
    simCardQuery: UseQueryResult<SimCard>;
    networkStateQuery: UseQueryResult<SimCardNetworkState>;
}

const DeviceDetailsPanel = ({
    deviceQuery: { data: device, isLoading },
    simCardQuery: { data: simCard },
    networkStateQuery: { data: networkState },
}: DeviceDetailsPanelProps) => {
    if (isLoading) {
        return (
            <TabPanel w={"100%"}>
                <Loading />
            </TabPanel>
        );
    }

    if (!device) {
        return (
            <TabPanel>
                <NotFound resource={"Device"} />
            </TabPanel>
        );
    }

    return (
        <TabPanel>
            <VStack w={"100%"} align={"stretch"} spacing={6}>
                <DeviceInfoCards device={device} />
                <HStack w={"100%"} align={"stretch"}>
                    <NetworkStateSummaryPaper networkState={networkState} />
                    <Paper
                        cardProps={{ flex: "auto" }}
                        header={
                            <HStack w={"100%"}>
                                <Heading size={"md"}>SIM Details</Heading>
                                <IconLink path={`/simcards/${simCard?.icc}`} ml={"auto"} newTab />
                            </HStack>
                        }
                    >
                        <HStack w={"100%"} gap={4}>
                            <VStack w={"100%"} align={"stretch"}>
                                <TextColumn
                                    heading={
                                        <HStack>
                                            <Heading size={"sm"}>ICC</Heading>
                                            <CopyButton value={simCard?.icc || ""} />
                                        </HStack>
                                    }
                                    grid
                                >
                                    <PlaceholderText text={simCard?.icc} />
                                </TextColumn>
                                <TextColumn heading={"SIM State"}>
                                    <SimCardStateCell icc={simCard?.icc} />
                                </TextColumn>
                            </VStack>
                            <VStack w={"100%"} align={"stretch"}>
                                <NetworkStateCircuitSwitchingState networkState={networkState} />
                                <NetworkStatePacketSwitchingState networkState={networkState} />
                            </VStack>
                        </HStack>
                    </Paper>
                </HStack>
                <Grid
                    templateAreas={`"left-top right"
                                    "left-bottom right"`}
                    templateRows={"1fr 1fr"}
                    templateColumns={"3fr 5fr"}
                    columnGap={4}
                    rowGap={4}
                >
                    <GridItem area={"left-top"}>
                        <DeviceHardwareDetailsPaper device={device} />
                    </GridItem>
                    <GridItem area={"left-bottom"}>
                        <DeviceModemDetailsPaper device={device} />
                    </GridItem>
                    <GridItem area={"right"}>
                        <NetworkStateMapLocation networkState={networkState} />
                    </GridItem>
                </Grid>
            </VStack>
        </TabPanel>
    );
};

export default DeviceDetailsPanel;
