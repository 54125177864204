import React from "react";
import { SimCardNetworkState } from "../../../api";
import { Grid, Heading } from "@chakra-ui/react";
import { Paper, SignalStrength, SignalStrengthTypes, TextColumn } from "@cobira/ui-library";
import { NetworkStateRadioTechnology } from "../NetworkStateRadioTechnology/NetworkStateRadioTechnology";
import { NetworkStateCellId } from "../NetworkStateCellId/NetworkStateCellId";
import { NetworkStateSignalStrength } from "../NetworkStateSignalStrength/NetworkStateSignalStrength";
import { NetworkStateNetwork } from "../NetworkStateNetwork/NetworkStateNetwork";
import { NetworkStateCountry } from "../NetworkStateCountry/NetworkStateCountry";

export interface NetworkStateSummaryProps {
    networkState?: SimCardNetworkState;
}

const NetworkStateSummaryPaper = ({ networkState }: NetworkStateSummaryProps) => {
    return (
        <Paper header={"Network Details"} withDivider cardProps={{ flexGrow: "1" }}>
            <Grid templateColumns="auto auto auto" gap="4">
                <NetworkStateSignalStrength
                    networkState={networkState}
                    signalStrengthType={SignalStrengthTypes.RSSI}
                    placeholderComponent={
                        <SignalStrength
                            signal={Number.MAX_SAFE_INTEGER}
                            type={SignalStrengthTypes.RSSI}
                            withTooltip={false}
                        />
                    }
                />
                <NetworkStateSignalStrength
                    networkState={networkState}
                    signalStrengthType={SignalStrengthTypes.RSRQ}
                    placeholderComponent={
                        <SignalStrength
                            signal={Number.MAX_SAFE_INTEGER}
                            type={SignalStrengthTypes.RSRQ}
                            withTooltip={false}
                        />
                    }
                />
                <TextColumn heading={<Heading size={"sm"}>Network</Heading>} grid>
                    <NetworkStateNetwork networkState={networkState} />
                </TextColumn>
                <TextColumn heading={<Heading size={"sm"}>Country</Heading>} grid>
                    <NetworkStateCountry networkState={networkState} />
                </TextColumn>
                <TextColumn heading={<Heading size={"sm"}>Radio Technology</Heading>} grid>
                    <NetworkStateRadioTechnology networkState={networkState} />
                </TextColumn>
                <TextColumn heading={<Heading size={"sm"}>Cell ID</Heading>} grid>
                    <NetworkStateCellId networkState={networkState} />
                </TextColumn>
            </Grid>
        </Paper>
    );
};

export default NetworkStateSummaryPaper;
