import React from "react";
import { Grid, Heading, HStack } from "@chakra-ui/react";
import {
    CountryInfo,
    DateTime,
    getCountry,
    ImeiInfo,
    Indicator,
    Paper,
    PlaceholderText,
    TextColumn,
} from "@cobira/ui-library";
import NetworkInfo from "../../../../components/NetworkInfo/NetworkInfo";
import { useQuery } from "@tanstack/react-query";
import { SimCard } from "../../../../api";
import { useApi } from "../../../../hooks/useApi";
import { RadioTechnologyLabels } from "../../../../labels/RadioTechnologyLabels";
import NetworkStateCircuitSwitchingState from "../../../../components/NetworkState/NetworkStateCircuitSwitchingState/NetworkStateCircuitSwitchingState";
import NetworkStatePacketSwitchingState from "../../../../components/NetworkState/NetworkStatePacketSwitchingState/NetworkStatePacketSwitchingState";

export interface SimCardNetworkStatePaperProps {
    simCard?: SimCard;
}

const SimCardNetworkStatePaper = ({ simCard }: SimCardNetworkStatePaperProps) => {
    const { simCardApi } = useApi();
    const { data: networkState, isLoading: isLoadingNetworkState } = useQuery(
        ["simcards", simCard?.icc, "networkstate"],
        () => simCardApi.getSimCardNetworkState({ icc: simCard?.icc || "" }),
        { enabled: simCard?.icc !== undefined },
    );
    return (
        <Paper header={"Network & device diagnostics"} withDivider>
            <Grid templateColumns="auto auto auto" gap="4">
                <NetworkStateCircuitSwitchingState networkState={networkState} />
                <NetworkStatePacketSwitchingState networkState={networkState} />
                <TextColumn
                    heading={
                        <HStack>
                            <Heading size={"sm"}>PDP Session State</Heading>
                            <Indicator color={networkState?.pdp?.activeSession ? "green" : "yellow"} />
                        </HStack>
                    }
                    grid
                    isLoading={isLoadingNetworkState}
                >
                    <DateTime value={networkState?.pdp?.recordedAt} />
                </TextColumn>
                <TextColumn heading="Current IMSI" grid>
                    <PlaceholderText text={networkState?.networkCredential?.imsi} />
                </TextColumn>
                <TextColumn heading="Country" grid>
                    <CountryInfo country={getCountry(networkState?.network?.country)} />
                </TextColumn>
                <TextColumn heading="Network" grid>
                    <NetworkInfo plmn={networkState?.network?.plmn} />
                </TextColumn>
                <TextColumn heading="Current IMEI" grid>
                    <ImeiInfo imei={networkState?.imei?.value} showCheckDigit as={"text"} />
                </TextColumn>
                <TextColumn heading="Radio Technology" grid>
                    <PlaceholderText text={RadioTechnologyLabels(networkState?.radioTechnology?.radioTechnology)} />
                </TextColumn>
            </Grid>
        </Paper>
    );
};

export default SimCardNetworkStatePaper;
