import React from "react";
import { AccountIcon, chakraIcon, ClientIcon, DashboardIcon, SidebarItem, SimCardIcon } from "@cobira/ui-library";
import AccountSidebarLink from "./AccountSidebarLink/AccountSidebarLink";
import { faRouter } from "@fortawesome/pro-regular-svg-icons";
import { Permission } from "../../utils/Permissions";

export const SidebarContent: SidebarItem[] = [
    {
        title: "Dashboards",
        path: "/",
        icon: DashboardIcon,
        items: [
            { title: "Main Dashboard", path: "/dashboards/account" },
            { title: "Customer Trends", path: "/dashboards/customer-compare" },
        ],
    },
    {
        title: "Clients",
        icon: ClientIcon,
        items: [
            { title: "Customers", path: "/customers" },
            { title: "Resellers", path: "/tenants" },
        ],
    },
    {
        title: "SIM Management",
        icon: SimCardIcon,
        items: [
            { title: "Inventory", path: "/simcards" },
            { title: "Activity", path: "/actions" },
        ],
    },
    {
        title: "Device Management",
        icon: chakraIcon(faRouter),
        permissions: {
            values: [Permission.device.read],
        },
        items: [
            { title: "Inventory", path: "/devices" },
            { title: "Activity", path: "/deviceactions" },
        ],
    },
    {
        title: <AccountSidebarLink />,
        icon: AccountIcon,
        items: [
            { title: "Configuration", path: "configuration" },
            { title: "Usage Records", path: "/usage-records" },
            { title: "Billing", path: "/billing/reports" },
            { title: "Activity", path: "/account/actions" },
            { title: "Team", path: "/team" },
            { title: "API Integrations", path: "/integrations" },
        ],
    },
];
