/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeviceActionRequestFilter } from './DeviceActionRequestFilter';
import {
    DeviceActionRequestFilterFromJSON,
    DeviceActionRequestFilterFromJSONTyped,
    DeviceActionRequestFilterToJSON,
} from './DeviceActionRequestFilter';

/**
 * Device reboot request. If no devices match the specified filter the request will fail.
 * @export
 * @interface DeviceRebootRequest
 */
export interface DeviceRebootRequest {
    /**
     * 
     * @type {DeviceActionRequestFilter}
     * @memberof DeviceRebootRequest
     */
    deviceFilter?: DeviceActionRequestFilter;
}

/**
 * Check if a given object implements the DeviceRebootRequest interface.
 */
export function instanceOfDeviceRebootRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DeviceRebootRequestFromJSON(json: any): DeviceRebootRequest {
    return DeviceRebootRequestFromJSONTyped(json, false);
}

export function DeviceRebootRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceRebootRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceFilter': !exists(json, 'deviceFilter') ? undefined : DeviceActionRequestFilterFromJSON(json['deviceFilter']),
    };
}

export function DeviceRebootRequestToJSON(value?: DeviceRebootRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deviceFilter': DeviceActionRequestFilterToJSON(value.deviceFilter),
    };
}

