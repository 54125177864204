/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Information regarding the attached cell tower
 * @export
 * @interface SimCardNetworkStateCell
 */
export interface SimCardNetworkStateCell {
    /**
     * 
     * @type {number}
     * @memberof SimCardNetworkStateCell
     */
    cellId?: number;
    /**
     * 
     * @type {number}
     * @memberof SimCardNetworkStateCell
     */
    locationAreaCode?: number;
    /**
     * 
     * @type {Date}
     * @memberof SimCardNetworkStateCell
     */
    recordedAt?: Date;
}

/**
 * Check if a given object implements the SimCardNetworkStateCell interface.
 */
export function instanceOfSimCardNetworkStateCell(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SimCardNetworkStateCellFromJSON(json: any): SimCardNetworkStateCell {
    return SimCardNetworkStateCellFromJSONTyped(json, false);
}

export function SimCardNetworkStateCellFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimCardNetworkStateCell {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cellId': !exists(json, 'cellId') ? undefined : json['cellId'],
        'locationAreaCode': !exists(json, 'locationAreaCode') ? undefined : json['locationAreaCode'],
        'recordedAt': !exists(json, 'recordedAt') ? undefined : (new Date(json['recordedAt'])),
    };
}

export function SimCardNetworkStateCellToJSON(value?: SimCardNetworkStateCell | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cellId': value.cellId,
        'locationAreaCode': value.locationAreaCode,
        'recordedAt': value.recordedAt === undefined ? undefined : (value.recordedAt.toISOString()),
    };
}

