import React from "react";
import { SimpleGrid, TabPanel, VStack } from "@chakra-ui/react";
import { SimCard } from "../../../../api";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import SimCardNetworkStatePaper from "./SimCardNetworkStatePaper";
import SimCardSummaryPaper from "./SimCardSummaryPaper";
import SimCardUsagePaper from "./SimCardUsagePaper";
import SimCardLabelsPaper from "./SimCardLabelsPaper";
import SimCardCustomerPaper from "./SimCardCustomerPaper";
import SimCardPdpSessionsPaper from "./SimCardPdpSessionsPaper";

export interface SimCardDetailsPanelProps {
    query: UseQueryResult<SimCard>;
}

const SimCardDetailsPanel = ({ query: { data: value, isLoading } }: SimCardDetailsPanelProps) => {
    return (
        <TabPanel w={"100%"}>
            <VStack w={"100%"} align={"stretch"} gap={6}>
                <SimpleGrid spacing={4} templateColumns="1fr 1fr">
                    <SimCardSummaryPaper isLoading={isLoading} simCard={value} />
                    <SimCardCustomerPaper isLoading={isLoading} simCard={value} />
                </SimpleGrid>
                <SimpleGrid spacing={4} templateColumns="2fr 1fr">
                    <SimCardNetworkStatePaper simCard={value} />
                    <SimCardLabelsPaper simCard={value} />
                </SimpleGrid>
                <SimCardPdpSessionsPaper simCard={value} />
                <SimCardUsagePaper simCard={value} />
            </VStack>
        </TabPanel>
    );
};

export default SimCardDetailsPanel;
