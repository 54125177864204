/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SimCardNetworkStateCell } from './SimCardNetworkStateCell';
import {
    SimCardNetworkStateCellFromJSON,
    SimCardNetworkStateCellFromJSONTyped,
    SimCardNetworkStateCellToJSON,
} from './SimCardNetworkStateCell';
import type { SimCardNetworkStateCellularPosition } from './SimCardNetworkStateCellularPosition';
import {
    SimCardNetworkStateCellularPositionFromJSON,
    SimCardNetworkStateCellularPositionFromJSONTyped,
    SimCardNetworkStateCellularPositionToJSON,
} from './SimCardNetworkStateCellularPosition';
import type { SimCardNetworkStateCircuitSwitchingLocationUpdate } from './SimCardNetworkStateCircuitSwitchingLocationUpdate';
import {
    SimCardNetworkStateCircuitSwitchingLocationUpdateFromJSON,
    SimCardNetworkStateCircuitSwitchingLocationUpdateFromJSONTyped,
    SimCardNetworkStateCircuitSwitchingLocationUpdateToJSON,
} from './SimCardNetworkStateCircuitSwitchingLocationUpdate';
import type { SimCardNetworkStateImei } from './SimCardNetworkStateImei';
import {
    SimCardNetworkStateImeiFromJSON,
    SimCardNetworkStateImeiFromJSONTyped,
    SimCardNetworkStateImeiToJSON,
} from './SimCardNetworkStateImei';
import type { SimCardNetworkStateIp } from './SimCardNetworkStateIp';
import {
    SimCardNetworkStateIpFromJSON,
    SimCardNetworkStateIpFromJSONTyped,
    SimCardNetworkStateIpToJSON,
} from './SimCardNetworkStateIp';
import type { SimCardNetworkStateNetwork } from './SimCardNetworkStateNetwork';
import {
    SimCardNetworkStateNetworkFromJSON,
    SimCardNetworkStateNetworkFromJSONTyped,
    SimCardNetworkStateNetworkToJSON,
} from './SimCardNetworkStateNetwork';
import type { SimCardNetworkStateNetworkCredential } from './SimCardNetworkStateNetworkCredential';
import {
    SimCardNetworkStateNetworkCredentialFromJSON,
    SimCardNetworkStateNetworkCredentialFromJSONTyped,
    SimCardNetworkStateNetworkCredentialToJSON,
} from './SimCardNetworkStateNetworkCredential';
import type { SimCardNetworkStatePdp } from './SimCardNetworkStatePdp';
import {
    SimCardNetworkStatePdpFromJSON,
    SimCardNetworkStatePdpFromJSONTyped,
    SimCardNetworkStatePdpToJSON,
} from './SimCardNetworkStatePdp';
import type { SimCardNetworkStateRadioTechnology } from './SimCardNetworkStateRadioTechnology';
import {
    SimCardNetworkStateRadioTechnologyFromJSON,
    SimCardNetworkStateRadioTechnologyFromJSONTyped,
    SimCardNetworkStateRadioTechnologyToJSON,
} from './SimCardNetworkStateRadioTechnology';
import type { SimCardNetworkStateSignalCondition } from './SimCardNetworkStateSignalCondition';
import {
    SimCardNetworkStateSignalConditionFromJSON,
    SimCardNetworkStateSignalConditionFromJSONTyped,
    SimCardNetworkStateSignalConditionToJSON,
} from './SimCardNetworkStateSignalCondition';

/**
 * 
 * @export
 * @interface SimCardNetworkState
 */
export interface SimCardNetworkState {
    /**
     * 
     * @type {string}
     * @memberof SimCardNetworkState
     */
    icc: string;
    /**
     * 
     * @type {SimCardNetworkStateNetwork}
     * @memberof SimCardNetworkState
     */
    network: SimCardNetworkStateNetwork;
    /**
     * 
     * @type {SimCardNetworkStateRadioTechnology}
     * @memberof SimCardNetworkState
     */
    radioTechnology: SimCardNetworkStateRadioTechnology;
    /**
     * 
     * @type {SimCardNetworkStateNetworkCredential}
     * @memberof SimCardNetworkState
     */
    networkCredential: SimCardNetworkStateNetworkCredential;
    /**
     * 
     * @type {SimCardNetworkStateCircuitSwitchingLocationUpdate}
     * @memberof SimCardNetworkState
     */
    circuitSwitchingLocationUpdate: SimCardNetworkStateCircuitSwitchingLocationUpdate;
    /**
     * 
     * @type {SimCardNetworkStateCircuitSwitchingLocationUpdate}
     * @memberof SimCardNetworkState
     */
    packetSwitchingLocationUpdate: SimCardNetworkStateCircuitSwitchingLocationUpdate;
    /**
     * 
     * @type {SimCardNetworkStateImei}
     * @memberof SimCardNetworkState
     */
    imei: SimCardNetworkStateImei;
    /**
     * 
     * @type {SimCardNetworkStateCellularPosition}
     * @memberof SimCardNetworkState
     */
    cellularPosition: SimCardNetworkStateCellularPosition;
    /**
     * 
     * @type {SimCardNetworkStateSignalCondition}
     * @memberof SimCardNetworkState
     */
    signalCondition: SimCardNetworkStateSignalCondition;
    /**
     * 
     * @type {SimCardNetworkStateCell}
     * @memberof SimCardNetworkState
     */
    cell: SimCardNetworkStateCell;
    /**
     * 
     * @type {SimCardNetworkStateIp}
     * @memberof SimCardNetworkState
     */
    ip: SimCardNetworkStateIp;
    /**
     * 
     * @type {SimCardNetworkStatePdp}
     * @memberof SimCardNetworkState
     */
    pdp: SimCardNetworkStatePdp;
}

/**
 * Check if a given object implements the SimCardNetworkState interface.
 */
export function instanceOfSimCardNetworkState(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "icc" in value;
    isInstance = isInstance && "network" in value;
    isInstance = isInstance && "radioTechnology" in value;
    isInstance = isInstance && "networkCredential" in value;
    isInstance = isInstance && "circuitSwitchingLocationUpdate" in value;
    isInstance = isInstance && "packetSwitchingLocationUpdate" in value;
    isInstance = isInstance && "imei" in value;
    isInstance = isInstance && "cellularPosition" in value;
    isInstance = isInstance && "signalCondition" in value;
    isInstance = isInstance && "cell" in value;
    isInstance = isInstance && "ip" in value;
    isInstance = isInstance && "pdp" in value;

    return isInstance;
}

export function SimCardNetworkStateFromJSON(json: any): SimCardNetworkState {
    return SimCardNetworkStateFromJSONTyped(json, false);
}

export function SimCardNetworkStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimCardNetworkState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'icc': json['icc'],
        'network': SimCardNetworkStateNetworkFromJSON(json['network']),
        'radioTechnology': SimCardNetworkStateRadioTechnologyFromJSON(json['radioTechnology']),
        'networkCredential': SimCardNetworkStateNetworkCredentialFromJSON(json['networkCredential']),
        'circuitSwitchingLocationUpdate': SimCardNetworkStateCircuitSwitchingLocationUpdateFromJSON(json['circuitSwitchingLocationUpdate']),
        'packetSwitchingLocationUpdate': SimCardNetworkStateCircuitSwitchingLocationUpdateFromJSON(json['packetSwitchingLocationUpdate']),
        'imei': SimCardNetworkStateImeiFromJSON(json['imei']),
        'cellularPosition': SimCardNetworkStateCellularPositionFromJSON(json['cellularPosition']),
        'signalCondition': SimCardNetworkStateSignalConditionFromJSON(json['signalCondition']),
        'cell': SimCardNetworkStateCellFromJSON(json['cell']),
        'ip': SimCardNetworkStateIpFromJSON(json['ip']),
        'pdp': SimCardNetworkStatePdpFromJSON(json['pdp']),
    };
}

export function SimCardNetworkStateToJSON(value?: SimCardNetworkState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'icc': value.icc,
        'network': SimCardNetworkStateNetworkToJSON(value.network),
        'radioTechnology': SimCardNetworkStateRadioTechnologyToJSON(value.radioTechnology),
        'networkCredential': SimCardNetworkStateNetworkCredentialToJSON(value.networkCredential),
        'circuitSwitchingLocationUpdate': SimCardNetworkStateCircuitSwitchingLocationUpdateToJSON(value.circuitSwitchingLocationUpdate),
        'packetSwitchingLocationUpdate': SimCardNetworkStateCircuitSwitchingLocationUpdateToJSON(value.packetSwitchingLocationUpdate),
        'imei': SimCardNetworkStateImeiToJSON(value.imei),
        'cellularPosition': SimCardNetworkStateCellularPositionToJSON(value.cellularPosition),
        'signalCondition': SimCardNetworkStateSignalConditionToJSON(value.signalCondition),
        'cell': SimCardNetworkStateCellToJSON(value.cell),
        'ip': SimCardNetworkStateIpToJSON(value.ip),
        'pdp': SimCardNetworkStatePdpToJSON(value.pdp),
    };
}

