/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SimCardNetworkStatePdp
 */
export interface SimCardNetworkStatePdp {
    /**
     * 
     * @type {boolean}
     * @memberof SimCardNetworkStatePdp
     */
    activeSession?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof SimCardNetworkStatePdp
     */
    recordedAt?: Date;
}

/**
 * Check if a given object implements the SimCardNetworkStatePdp interface.
 */
export function instanceOfSimCardNetworkStatePdp(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SimCardNetworkStatePdpFromJSON(json: any): SimCardNetworkStatePdp {
    return SimCardNetworkStatePdpFromJSONTyped(json, false);
}

export function SimCardNetworkStatePdpFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimCardNetworkStatePdp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activeSession': !exists(json, 'activeSession') ? undefined : json['activeSession'],
        'recordedAt': !exists(json, 'recordedAt') ? undefined : (new Date(json['recordedAt'])),
    };
}

export function SimCardNetworkStatePdpToJSON(value?: SimCardNetworkStatePdp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activeSession': value.activeSession,
        'recordedAt': value.recordedAt === undefined ? undefined : (value.recordedAt.toISOString()),
    };
}

