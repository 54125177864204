/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeviceConnectionState } from './DeviceConnectionState';
import {
    DeviceConnectionStateFromJSON,
    DeviceConnectionStateFromJSONTyped,
    DeviceConnectionStateToJSON,
} from './DeviceConnectionState';

/**
 * Device information
 * @export
 * @interface Device
 */
export interface Device {
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    identifier: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    hardwareRevision?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    imei?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    productCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    firmware?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    manufacturer?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    model?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    modemFirmware?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    modemManufacturer?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    modemModel?: string;
    /**
     * 
     * @type {Date}
     * @memberof Device
     */
    lastConnectedAt?: Date;
    /**
     * 
     * @type {DeviceConnectionState}
     * @memberof Device
     */
    connectionState?: DeviceConnectionState;
    /**
     * 
     * @type {boolean}
     * @memberof Device
     */
    authenticated?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Device
     */
    creditExpiresAt?: Date;
    /**
     * 
     * @type {DeviceConnectionState}
     * @memberof Device
     */
    mobileConnectionState?: DeviceConnectionState;
    /**
     * ISO8601 duration format
     * @type {string}
     * @memberof Device
     */
    mobileConnectionUptime?: string;
    /**
     * ISO8601 duration format
     * @type {string}
     * @memberof Device
     */
    deviceUptime?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    icc?: string;
    /**
     * 
     * @type {Date}
     * @memberof Device
     */
    updatedAt: Date;
}

/**
 * Check if a given object implements the Device interface.
 */
export function instanceOfDevice(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "identifier" in value;
    isInstance = isInstance && "updatedAt" in value;

    return isInstance;
}

export function DeviceFromJSON(json: any): Device {
    return DeviceFromJSONTyped(json, false);
}

export function DeviceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Device {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'identifier': json['identifier'],
        'hardwareRevision': !exists(json, 'hardwareRevision') ? undefined : json['hardwareRevision'],
        'imei': !exists(json, 'imei') ? undefined : json['imei'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'productCode': !exists(json, 'productCode') ? undefined : json['productCode'],
        'firmware': !exists(json, 'firmware') ? undefined : json['firmware'],
        'manufacturer': !exists(json, 'manufacturer') ? undefined : json['manufacturer'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'modemFirmware': !exists(json, 'modemFirmware') ? undefined : json['modemFirmware'],
        'modemManufacturer': !exists(json, 'modemManufacturer') ? undefined : json['modemManufacturer'],
        'modemModel': !exists(json, 'modemModel') ? undefined : json['modemModel'],
        'lastConnectedAt': !exists(json, 'lastConnectedAt') ? undefined : (new Date(json['lastConnectedAt'])),
        'connectionState': !exists(json, 'connectionState') ? undefined : DeviceConnectionStateFromJSON(json['connectionState']),
        'authenticated': !exists(json, 'authenticated') ? undefined : json['authenticated'],
        'creditExpiresAt': !exists(json, 'creditExpiresAt') ? undefined : (new Date(json['creditExpiresAt'])),
        'mobileConnectionState': !exists(json, 'mobileConnectionState') ? undefined : DeviceConnectionStateFromJSON(json['mobileConnectionState']),
        'mobileConnectionUptime': !exists(json, 'mobileConnectionUptime') ? undefined : json['mobileConnectionUptime'],
        'deviceUptime': !exists(json, 'deviceUptime') ? undefined : json['deviceUptime'],
        'icc': !exists(json, 'icc') ? undefined : json['icc'],
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function DeviceToJSON(value?: Device | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'identifier': value.identifier,
        'hardwareRevision': value.hardwareRevision,
        'imei': value.imei,
        'name': value.name,
        'productCode': value.productCode,
        'firmware': value.firmware,
        'manufacturer': value.manufacturer,
        'model': value.model,
        'modemFirmware': value.modemFirmware,
        'modemManufacturer': value.modemManufacturer,
        'modemModel': value.modemModel,
        'lastConnectedAt': value.lastConnectedAt === undefined ? undefined : (value.lastConnectedAt.toISOString()),
        'connectionState': DeviceConnectionStateToJSON(value.connectionState),
        'authenticated': value.authenticated,
        'creditExpiresAt': value.creditExpiresAt === undefined ? undefined : (value.creditExpiresAt.toISOString()),
        'mobileConnectionState': DeviceConnectionStateToJSON(value.mobileConnectionState),
        'mobileConnectionUptime': value.mobileConnectionUptime,
        'deviceUptime': value.deviceUptime,
        'icc': value.icc,
        'updatedAt': (value.updatedAt.toISOString()),
    };
}

