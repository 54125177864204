import React from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { DataUsageRecord, SimCard } from "../../api";
import {
    CobiraTable,
    CountryInfo,
    DataText,
    DateTime,
    getCountry,
    PlaceholderText,
    SlimUuid,
    usePageNavigation,
    usePagination,
} from "@cobira/ui-library";
import NetworkInfo from "../NetworkInfo/NetworkInfo";
import { RadioTechnologyLabels } from "../../labels/RadioTechnologyLabels";
import { UsagePackageCell } from "../Cells/UsagePackageCell/UsagePackageCell";
import { useApi } from "../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";

const dataSessionColumn = createColumnHelper<DataUsageRecord>();
const COLUMNS = [
    dataSessionColumn.accessor("id", {
        id: "id",
        header: "Usage ID",
        cell: row => <SlimUuid uuid={row.getValue() || ""} />,
    }),
    dataSessionColumn.accessor("imsi", {
        id: "imsi",
        header: "IMSI",
        cell: row => row.getValue(),
    }),
    dataSessionColumn.accessor("plmn", {
        id: "plmn",
        header: "Network",
        cell: row => <NetworkInfo plmn={row.getValue()} />,
    }),
    dataSessionColumn.accessor("countryCode", {
        id: "country",
        header: "Country",
        cell: row => <CountryInfo country={getCountry(row.getValue())} />,
    }),
    dataSessionColumn.accessor("radioTechnology", {
        id: "radioTechnology",
        header: "RAT",
        cell: row => <PlaceholderText text={RadioTechnologyLabels(row.getValue())} />,
    }),
    dataSessionColumn.accessor("openedAt", {
        id: "openedAt",
        header: "Opened At",
        cell: row => <DateTime value={row.getValue()} />,
    }),
    dataSessionColumn.accessor("closedAt", {
        id: "closedAt",
        header: "Closed At",
        cell: row => <DateTime value={row.getValue()} />,
    }),
    dataSessionColumn.accessor("billableBytes", {
        id: "totalBytes",
        header: "Total Usage",
        cell: row => <DataText bytes={row.getValue()} decimals={2} />,
    }),
    dataSessionColumn.accessor(row => row, {
        id: "bundle",
        header: "Bundle",
        cell: row => <UsagePackageCell icc={row.getValue().icc} usagePackageId={row.getValue().usagePackageId} />,
    }),
];

export interface DataUsageTableProps {
    simCard?: SimCard;
    tableKey: string;
}

const DataUsageTable = ({ simCard, tableKey }: DataUsageTableProps) => {
    const { usageRecordApi } = useApi();
    const pageNavigation = usePageNavigation<DataUsageRecord>({
        route: value => `/simcards/${value.icc}/usage-records/data/${value.id}`,
    });
    const { pageState, setPageState } = usePagination({
        pageIndex: 0,
        pageSize: 20,
    });

    const { data: dataSessions, isLoading: isLoadingDataSessions } = useQuery(
        ["simcards", simCard?.icc, "usage-records", "data", pageState],
        () => {
            const now = new Date();
            const oneMonthAgo = new Date(now);
            oneMonthAgo.setMonth(now.getMonth() - 1);
            return usageRecordApi.getDataUsageRecords({
                icc: simCard?.icc || "",
                pageNumber: pageState.pageIndex,
                pageSize: pageState.pageSize,
                fromDate: oneMonthAgo,
                toDate: now,
            });
        },
        { enabled: simCard?.icc !== undefined },
    );
    return (
        <CobiraTable
            columns={COLUMNS}
            data={dataSessions?.content || []}
            isLoading={isLoadingDataSessions}
            withPagination={{
                pageSize: pageState.pageSize,
                pageIndex: pageState.pageIndex,
                onPaginationChange: setPageState,
                totalRowCount: dataSessions?.pageProperties?.totalElements || 0,
                totalPageCount: dataSessions?.pageProperties?.totalPages || 0,
            }}
            withRowClick={{
                enableHoverStyle: true,
                onRowClicked: pageNavigation.navigate,
            }}
            withColumnSelection={{
                tableKey: tableKey,
                maxColumnCount: 7,
                minColumnCount: 2,
            }}
        />
    );
};

export default DataUsageTable;
