/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * LAN host seen attached to a device
 * @export
 * @interface DeviceLanHost
 */
export interface DeviceLanHost {
    /**
     * 
     * @type {string}
     * @memberof DeviceLanHost
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceLanHost
     */
    portId: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceLanHost
     */
    mac: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceLanHost
     */
    clientId: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceLanHost
     */
    ip: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceLanHost
     */
    hostName?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceLanHost
     */
    vendor?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceLanHost
     */
    connected: boolean;
    /**
     * 
     * @type {Date}
     * @memberof DeviceLanHost
     */
    fetchDate: Date;
}

/**
 * Check if a given object implements the DeviceLanHost interface.
 */
export function instanceOfDeviceLanHost(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "portId" in value;
    isInstance = isInstance && "mac" in value;
    isInstance = isInstance && "clientId" in value;
    isInstance = isInstance && "ip" in value;
    isInstance = isInstance && "connected" in value;
    isInstance = isInstance && "fetchDate" in value;

    return isInstance;
}

export function DeviceLanHostFromJSON(json: any): DeviceLanHost {
    return DeviceLanHostFromJSONTyped(json, false);
}

export function DeviceLanHostFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceLanHost {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'portId': json['portId'],
        'mac': json['mac'],
        'clientId': json['clientId'],
        'ip': json['ip'],
        'hostName': !exists(json, 'hostName') ? undefined : json['hostName'],
        'vendor': !exists(json, 'vendor') ? undefined : json['vendor'],
        'connected': json['connected'],
        'fetchDate': (new Date(json['fetchDate'])),
    };
}

export function DeviceLanHostToJSON(value?: DeviceLanHost | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'portId': value.portId,
        'mac': value.mac,
        'clientId': value.clientId,
        'ip': value.ip,
        'hostName': value.hostName,
        'vendor': value.vendor,
        'connected': value.connected,
        'fetchDate': (value.fetchDate.toISOString()),
    };
}

