import React from "react";
import { Authentication, MainLayout, useCobiraEnvironment } from "@cobira/ui-library";
import SimCardListPage from "./pages/simcard/SimCardListPage/SimCardListPage";
import { Auth0Provider } from "@auth0/auth0-react";
import { Navigate, Route, Routes } from "react-router";
import SimCardDetailPage from "./pages/simcard/SimCardDetailPage/SimCardDetailPage";
import CustomerListPage from "./pages/customer/CustomerListPage/CustomerListPage";
import CustomerDetailPage from "./pages/customer/CustomerDetailPage/CustomerDetailPage";
import BillingGroupDetailPage from "./pages/billinggroup/BillingGroupDetailPage/BillingGroupDetailPage";
import CustomerInvoiceDetailPage from "./pages/customer/CustomerInvoiceDetailPage/CustomerInvoiceDetailPage";
import MvnoActionListPage from "./pages/action/MvnoActionListPage/MvnoActionListPage";
import UsagePackageTypeDetailPage from "./pages/usagepackagetype/UsagePackageTypeDetailPage/UsagePackageTypeDetailPage";
import IntegrationListPage from "./pages/integration/IntegrationListPage/IntegrationListPage";
import IntegrationDetailPage from "./pages/integration/IntegrationDetailPage/IntegrationDetailPage";
import TenantListPage from "./pages/tenant/TenantListPage/TenantListPage";
import TenantDetailPage from "./pages/tenant/TenantDetailPage/TenantDetailPage";
import RatePlanDetailPage from "./pages/rateplan/RatePlanDetailPage/RatePlanDetailPage";
import RatePlanListPage from "./pages/rateplan/RatePlanListPage/RatePlanListPage";
import RatePlanCreatePage from "./pages/rateplan/RatePlanCreatePage/RatePlanCreatePage";
import RatePlanUpdatePage from "./pages/rateplan/RatePlanUpdatePage/RatePlanUpdatePage";
import CustomerRecurringFeeDetailPage from "./pages/customer/CustomerRecurringFeeDetailPage/CustomerRecurringFeeDetailPage";
import UserListPage from "./pages/user/UserListPage/UserListPage";
import TenantCostPlanDetailPage from "./pages/tenant/TenantCostPlanDetailPage/TenantCostPlanDetailPage";
import TenantNetworkCredentialTypeDetailPage from "./pages/tenant/TenantNetworkCredentialTypeDetailPage/TenantNetworkCredentialTypeDetailPage";
import TenantSimConfigurationDetailPage from "./pages/tenant/TenantSimConfigurationDetailPage/TenantSimConfigurationDetailPage";
import MvnoActionDetailPage from "./pages/action/MvnoActionDetailsPage/MvnoActionDetailPage";
import MvnoSidebar from "./components/MvnoSidebar/MvnoSidebar";
import DashboardsPage from "./pages/dashboards/DashboardsPage/DashboardsPage";
import ConfigurationPage from "./pages/configuration/ConfigurationPage";
import BillingReportsPage from "./pages/billing/BillingReportsPage";
import TenantUsagePackageTypeDetailPage from "./pages/tenant/TenantUsagePackageTypeDetailPage/TenantUsagePackageTypeDetailPage";
import CustomerCompareDashboardPage from "./pages/dashboards/CustomerCompareDashboardPage/CustomerCompareDashboardPage";
import BillingPeriodReportsPage from "./pages/billing/BillingPeriodReportsPage";
import BillingReportDetailsPage from "./pages/billing/BillingReportDetailsPage";
import SimCardUsagePackageDetailPage from "./pages/simcard/SimCardUsagePackageDetailPage/SimCardUsagePackageDetailPage";
import UsageRecordListPage from "./pages/usagerecord/UsageRecordListPage/UsageRecordListPage";
import DataUsageDetailPage from "./pages/usagerecord/DataUsageDetailPage/DataUsageDetailPage";
import SmsUsageDetailPage from "./pages/usagerecord/SmsUsageDetailPage/SmsUsageDetailPage";
import SimCardDataUsageDetailPage from "./pages/simcard/SimCardDetailPage/Usage/SimCardDataUsageDetailPage";
import SimCardSmsUsageDetailPage from "./pages/simcard/SimCardDetailPage/Usage/SimCardSmsUsageDetailPage";
import CreateCostOverrideCostPlanPage from "./pages/tenant/TenantNetworkCredentialTypeDetailPage/CreateCostOverrideCostPlanPage/CreateCostOverrideCostPlanPage";
import CreateBaseCostPlanPage from "./pages/tenant/TenantNetworkCredentialTypeDetailPage/CreateBaseCostPlanPage/CreateBaseCostPlanPage";
import UpdateCostPlanPage from "./pages/tenant/TenantNetworkCredentialTypeDetailPage/UpdateCostPlanPage/UpdateCostPlanPage";
import NetworkCredentialTypeDetailPage from "./pages/configuration/NetworkCredentialTypeDetailPage/NetworkCredentialTypeDetailPage";
import CostPlanDetailPage from "./pages/configuration/CostPlanDetailPage/CostPlanDetailPage";
import SimConfigurationDetailPage from "./pages/configuration/SimConfigurationDetailPage/SimConfigurationDetailPage";
import DeviceListPage from "./pages/device/DeviceListPage/DeviceListPage";
import DeviceDetailPage from "./pages/device/DeviceDetailPage/DeviceDetailPage";
import DeviceActionListPage from "./pages/action/DeviceActionListPage/DeviceActionListPage";
import DeviceActionDetailPage from "./pages/action/DeviceActionDetailsPage/DeviceActionDetailPage";
import UpdateDeviceACLPage from "./pages/device/UpdateDeviceACLPage/UpdateDeviceACLPage";
import AccountActionListPage from "./pages/action/AccountActionListPage/AccountActionListPage";
import AccountActionDetailPage from "./pages/action/AccountActionDetailsPage/AccountActionDetailPage";

function App() {
    const [, environment] = useCobiraEnvironment();

    return (
        <Auth0Provider
            domain={environment.auth.domain}
            clientId={environment.auth.clientId}
            cacheLocation={"localstorage"}
            useRefreshTokens={true}
            authorizationParams={{
                audience: environment.auth.audience,
                redirect_uri: environment.auth.redirectUrl,
                scope: "openid email profile offline_access",
            }}
        >
            <Authentication>
                <MainLayout sidebar={<MvnoSidebar />}>
                    <Routes>
                        <Route path="/actions" element={<MvnoActionListPage />} />
                        <Route path="/actions/:actionId" element={<MvnoActionDetailPage />} />

                        <Route path="/simcards" element={<SimCardListPage />} />
                        <Route path="/simcards/:icc" element={<SimCardDetailPage />} />
                        <Route
                            path="/simcards/:icc/usage-records/data/:usageRecordId"
                            element={<SimCardDataUsageDetailPage />}
                        />
                        <Route
                            path="/simcards/:icc/usage-records/sms/:usageRecordId"
                            element={<SimCardSmsUsageDetailPage />}
                        />
                        <Route
                            path="/simcards/:icc/bundles/:usagePackageId"
                            element={<SimCardUsagePackageDetailPage />}
                        />

                        <Route path="/devices" element={<DeviceListPage />} />
                        <Route path="/devices/:deviceId" element={<DeviceDetailPage />} />
                        <Route path="/devices/:deviceId/acl/update" element={<UpdateDeviceACLPage />} />

                        <Route path="/deviceactions" element={<DeviceActionListPage />} />
                        <Route path="/deviceactions/:actionId" element={<DeviceActionDetailPage />} />

                        <Route path="/customers" element={<CustomerListPage />} />
                        <Route path="/customers/:customerId" element={<CustomerDetailPage />} />
                        <Route
                            path="/customers/:customerId/billinggroups/:billingGroupId"
                            element={<BillingGroupDetailPage />}
                        />
                        <Route
                            path="/customers/:customerId/bundles/:usagePackageTypeId"
                            element={<UsagePackageTypeDetailPage originPath={"customer"} />}
                        />
                        <Route
                            path="/customers/:customerId/invoices/:invoiceId"
                            element={<CustomerInvoiceDetailPage />}
                        />
                        <Route
                            path="/customers/:customerId/recurringfees/:customerRecurringFeeId"
                            element={<CustomerRecurringFeeDetailPage />}
                        />

                        <Route path="/account/actions" element={<AccountActionListPage />} />
                        <Route path="/account/actions/:actionId" element={<AccountActionDetailPage />} />

                        <Route path="/configuration" element={<ConfigurationPage />} />
                        <Route
                            path="/configuration/networkcredentialtypes/:networkCredentialTypeId"
                            element={<NetworkCredentialTypeDetailPage />}
                        />
                        <Route
                            path="/configuration/networkcredentialtypes/:networkCredentialTypeId/costplans/:costPlanId"
                            element={<CostPlanDetailPage />}
                        />
                        <Route
                            path="/configuration/simconfigurations/:simConfigurationId"
                            element={<SimConfigurationDetailPage />}
                        />
                        <Route
                            path="/configuration/bundles/:usagePackageTypeId"
                            element={<UsagePackageTypeDetailPage originPath={"configuration"} />}
                        />

                        <Route path="/integrations" element={<IntegrationListPage />} />
                        <Route path="/integrations/:integrationId" element={<IntegrationDetailPage />} />

                        <Route path="/tenants" element={<TenantListPage />} />
                        <Route path="/tenants/:tenantId" element={<TenantDetailPage />} />
                        <Route
                            path="/tenants/:tenantId/networkcredentialtypes/:networkCredentialTypeId"
                            element={<TenantNetworkCredentialTypeDetailPage />}
                        />
                        <Route
                            path="/tenants/:tenantId/networkcredentialtypes/:networkCredentialTypeId/costplans/:costPlanId"
                            element={<TenantCostPlanDetailPage />}
                        />
                        <Route
                            path="/tenants/:tenantId/networkcredentialtypes/:networkCredentialTypeId/costplans/override-create"
                            element={<CreateCostOverrideCostPlanPage />}
                        />
                        <Route
                            path="/tenants/:tenantId/networkcredentialtypes/:networkCredentialTypeId/costplans/base-create"
                            element={<CreateBaseCostPlanPage />}
                        />
                        <Route
                            path="/tenants/:tenantId/networkcredentialtypes/:networkCredentialTypeId/costplans/:costPlanId/update"
                            element={<UpdateCostPlanPage />}
                        />
                        <Route
                            path="/tenants/:tenantId/simconfigurations/:simConfigurationId"
                            element={<TenantSimConfigurationDetailPage />}
                        />
                        <Route
                            path="/tenants/:tenantId/bundles/:usagePackageTypeId"
                            element={<TenantUsagePackageTypeDetailPage />}
                        />

                        <Route path="/usage-records" element={<UsageRecordListPage />} />
                        <Route path="/usage-records/data/:usageRecordId" element={<DataUsageDetailPage />} />
                        <Route path="/usage-records/sms/:usageRecordId" element={<SmsUsageDetailPage />} />

                        <Route path="/rateplans" element={<RatePlanListPage />} />
                        <Route path="/rateplans/create" element={<RatePlanCreatePage />} />
                        <Route path="/rateplans/:ratePlanId" element={<RatePlanDetailPage />} />
                        <Route path="/rateplans/:ratePlanId/update" element={<RatePlanUpdatePage />} />
                        <Route path="/team" element={<UserListPage />} />
                        <Route path="/billing/reports" element={<BillingReportsPage />} />
                        <Route path="/billing/reports/:year/:month" element={<BillingPeriodReportsPage />} />
                        <Route path="/billing/reports/:billingReportId" element={<BillingReportDetailsPage />} />

                        <Route path="/" element={<Navigate to={"/dashboards/account"} />} />
                        <Route path="/dashboards/account" element={<DashboardsPage />} />
                        <Route path="/dashboards/customer-compare" element={<CustomerCompareDashboardPage />} />
                    </Routes>
                </MainLayout>
            </Authentication>
        </Auth0Provider>
    );
}

export default App;
