import { PermissionUtils } from "@cobira/ui-library";

export const Permission = {
    accessToken: PermissionUtils.readWrite("access-token"),
    action: PermissionUtils.readWrite("action"),
    billingGroup: PermissionUtils.readWrite("billing-group"),
    integration: PermissionUtils.readWrite("integration"),
    product: PermissionUtils.readWrite("product"),
    simCard: PermissionUtils.readWrite("simcard"),
    usagePackage: PermissionUtils.readWrite("usage-package"),
    user: PermissionUtils.readWrite("user"),
    webhook: PermissionUtils.readWrite("webhook"),
    network: PermissionUtils.read("network"),
    device: PermissionUtils.readWrite("device"),
    invoice: PermissionUtils.readWrite("invoice"),
    tenant: PermissionUtils.readWrite("tenant"),
    customer: PermissionUtils.readWrite("customer"),
    ratePlan: PermissionUtils.readWrite("rate-plan"),
    recurringFee: PermissionUtils.readWrite("recurring-fee"),
    role: PermissionUtils.read("role"),
};
