/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DeviceACLMode = {
    Whitelist: 'WHITELIST'
} as const;
export type DeviceACLMode = typeof DeviceACLMode[keyof typeof DeviceACLMode];


export function DeviceACLModeFromJSON(json: any): DeviceACLMode {
    return DeviceACLModeFromJSONTyped(json, false);
}

export function DeviceACLModeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceACLMode {
    return json as DeviceACLMode;
}

export function DeviceACLModeToJSON(value?: DeviceACLMode | null): any {
    return value as any;
}

