/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeviceConnectionState } from './DeviceConnectionState';
import {
    DeviceConnectionStateFromJSON,
    DeviceConnectionStateFromJSONTyped,
    DeviceConnectionStateToJSON,
} from './DeviceConnectionState';

/**
 * 
 * @export
 * @interface DeviceActionRequestFilter
 */
export interface DeviceActionRequestFilter {
    /**
     * 
     * @type {Set<string>}
     * @memberof DeviceActionRequestFilter
     */
    deviceIdSet?: Set<string> | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceActionRequestFilter
     */
    icc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceActionRequestFilter
     */
    identifier?: string | null;
    /**
     * 
     * @type {DeviceConnectionState}
     * @memberof DeviceActionRequestFilter
     */
    deviceConnectionState?: DeviceConnectionState;
    /**
     * 
     * @type {string}
     * @memberof DeviceActionRequestFilter
     */
    search?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceActionRequestFilter
     */
    model?: string | null;
}

/**
 * Check if a given object implements the DeviceActionRequestFilter interface.
 */
export function instanceOfDeviceActionRequestFilter(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DeviceActionRequestFilterFromJSON(json: any): DeviceActionRequestFilter {
    return DeviceActionRequestFilterFromJSONTyped(json, false);
}

export function DeviceActionRequestFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceActionRequestFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceIdSet': !exists(json, 'deviceIdSet') ? undefined : json['deviceIdSet'],
        'icc': !exists(json, 'icc') ? undefined : json['icc'],
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'deviceConnectionState': !exists(json, 'deviceConnectionState') ? undefined : DeviceConnectionStateFromJSON(json['deviceConnectionState']),
        'search': !exists(json, 'search') ? undefined : json['search'],
        'model': !exists(json, 'model') ? undefined : json['model'],
    };
}

export function DeviceActionRequestFilterToJSON(value?: DeviceActionRequestFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deviceIdSet': value.deviceIdSet === undefined ? undefined : value.deviceIdSet === null ? null : Array.from(value.deviceIdSet as Set<any>),
        'icc': value.icc,
        'identifier': value.identifier,
        'deviceConnectionState': DeviceConnectionStateToJSON(value.deviceConnectionState),
        'search': value.search,
        'model': value.model,
    };
}

