/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeviceACLMode } from './DeviceACLMode';
import {
    DeviceACLModeFromJSON,
    DeviceACLModeFromJSONTyped,
    DeviceACLModeToJSON,
} from './DeviceACLMode';
import type { DeviceACLUpdateRequestAclConfigsInnerAclEntriesInner } from './DeviceACLUpdateRequestAclConfigsInnerAclEntriesInner';
import {
    DeviceACLUpdateRequestAclConfigsInnerAclEntriesInnerFromJSON,
    DeviceACLUpdateRequestAclConfigsInnerAclEntriesInnerFromJSONTyped,
    DeviceACLUpdateRequestAclConfigsInnerAclEntriesInnerToJSON,
} from './DeviceACLUpdateRequestAclConfigsInnerAclEntriesInner';

/**
 * 
 * @export
 * @interface DeviceACLUpdateRequestAclConfigsInner
 */
export interface DeviceACLUpdateRequestAclConfigsInner {
    /**
     * 
     * @type {string}
     * @memberof DeviceACLUpdateRequestAclConfigsInner
     */
    deviceId: string;
    /**
     * 
     * @type {DeviceACLMode}
     * @memberof DeviceACLUpdateRequestAclConfigsInner
     */
    aclMode: DeviceACLMode;
    /**
     * 
     * @type {Array<DeviceACLUpdateRequestAclConfigsInnerAclEntriesInner>}
     * @memberof DeviceACLUpdateRequestAclConfigsInner
     */
    aclEntries?: Array<DeviceACLUpdateRequestAclConfigsInnerAclEntriesInner>;
}

/**
 * Check if a given object implements the DeviceACLUpdateRequestAclConfigsInner interface.
 */
export function instanceOfDeviceACLUpdateRequestAclConfigsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deviceId" in value;
    isInstance = isInstance && "aclMode" in value;

    return isInstance;
}

export function DeviceACLUpdateRequestAclConfigsInnerFromJSON(json: any): DeviceACLUpdateRequestAclConfigsInner {
    return DeviceACLUpdateRequestAclConfigsInnerFromJSONTyped(json, false);
}

export function DeviceACLUpdateRequestAclConfigsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceACLUpdateRequestAclConfigsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceId': json['deviceId'],
        'aclMode': DeviceACLModeFromJSON(json['aclMode']),
        'aclEntries': !exists(json, 'aclEntries') ? undefined : ((json['aclEntries'] as Array<any>).map(DeviceACLUpdateRequestAclConfigsInnerAclEntriesInnerFromJSON)),
    };
}

export function DeviceACLUpdateRequestAclConfigsInnerToJSON(value?: DeviceACLUpdateRequestAclConfigsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deviceId': value.deviceId,
        'aclMode': DeviceACLModeToJSON(value.aclMode),
        'aclEntries': value.aclEntries === undefined ? undefined : ((value.aclEntries as Array<any>).map(DeviceACLUpdateRequestAclConfigsInnerAclEntriesInnerToJSON)),
    };
}

