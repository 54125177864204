import React, { useMemo, useState } from "react";
import {
    CobiraTable,
    Country,
    CountryInfo,
    getCountries,
    getCountry,
    LocationUpdateState,
    PageLayout,
    PageTitle,
    PlaceholderText,
    SearchBar,
    SearchRangeInput,
    SearchSelectInput,
    SearchSingleInput,
    usePageNavigation,
    useUrlPagination,
} from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { SimCard, SimCardActionRequestFilter, SimCardCapabilityName } from "../../../api";
import { useQuery } from "@tanstack/react-query";
import { SearchSelectSimCardNetworkStateInput } from "../../../components/SimCardSearch/SearchSelectSimCardNetworkState/SearchSelectSimCardNetworkStateInput";
import { VStack } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import NetworkInfo from "../../../components/NetworkInfo/NetworkInfo";
import { SimCardStateCell } from "../../../components/Cells/SimCardStateCell/SimCardStateCell";
import { BillingGroupCell } from "../../../components/Cells/BillingGroupCell/BillingGroupCell";
import { SimNetworkStateCell } from "../../../components/Cells/SimNetworkStateCell/SimNetworkStateCell";
import { SimCardCustomerCell } from "../../../components/Cells/SimCardCustomerCell/SimCardCustomerCell";
import { SearchSelectBillingGroupInput } from "../../../components/SimCardSearch/SearchSelectBillingGroupInput/SearchSelectBillingGroupInput";
import { SearchSelectCustomerInput } from "../../../components/SimCardSearch/SearchSelectCustomerInput/SearchSelectCustomerInput";
import { filterHasValues } from "../../../utils/filterUtils";
import SimCardActionModals, { SimActionType } from "../../../components/Modal/SimCardActionModals/SimCardActionModals";
import SimCardQuickFindTooltip from "../../../components/SimCardQuickFindTooltip/SimCardQuickFindTooltip";
import useSimCardSearch, { isMultiImsiFilterLabel } from "../../../hooks/useSimCardSearch";
import { SimCardNetworkCredentialsCell } from "../../../components/Cells/SimCardNetworkCredentialsCell/SimCardNetworkCredentialsCell";
import SimCardNetworkCredentialTypesCell from "../../../components/Cells/SimCardNetworkCredentialTypesCell/SimCardNetworkCredentialTypesCell";
import { SearchSelectConsumptionState } from "../../../components/SearchSelectConsumptionState/SearchSelectConsumptionState";
import { mapBooleanFilter } from "../../../utils/mapperUtils";
import { SimCardCapabilityNameLabels } from "../../../labels/SimCardCapabilityNameLabels";
import { SearchSelectNetworkCredentialTypeInput } from "../../../components/SearchSelectNetworkCredentialTypeInput/SearchSelectNetworkCredentialTypeInput";
import { SearchSelectSimConfigurationInput } from "../../../components/SearchSelectSimConfigurationInput/SearchSelectSimConfigurationInput";
import { SearchSelectUsagePackageTypeInput } from "../../../components/SearchSelectUsagePackageTypeInput/SearchSelectUsagePackageTypeInput";

const simCardColumn = createColumnHelper<SimCard>();
const COLUMNS = [
    simCardColumn.accessor("icc", {
        id: "simCardState",
        header: "SIM State",
        cell: row => <SimCardStateCell icc={row.getValue()} />,
    }),
    simCardColumn.accessor("icc", { id: "icc", header: "ICC", cell: row => <PlaceholderText text={row.getValue()} /> }),
    simCardColumn.accessor("customName", {
        id: "customName",
        header: "Name",
        cell: row => <PlaceholderText text={row.getValue()} />,
    }),
    simCardColumn.accessor("billingGroupId", {
        id: "customer",
        header: "Customer",
        cell: row => <SimCardCustomerCell billingGroupId={row.getValue()} />,
    }),
    simCardColumn.accessor("billingGroupId", {
        id: "billingGroup",
        header: "Billing Group",
        cell: row => <BillingGroupCell billingGroupId={row.getValue()} />,
    }),
    simCardColumn.accessor("icc", {
        id: "activeImsi",
        header: "Active IMSI",
        cell: row => (
            <SimNetworkStateCell
                icc={row.getValue()}
                property={networkState => <PlaceholderText text={networkState?.networkCredential.imsi} />}
            />
        ),
    }),
    simCardColumn.accessor("icc", {
        id: "networkCredentials",
        header: "IMSIs",
        cell: row => <SimCardNetworkCredentialsCell icc={row.getValue()} />,
    }),
    simCardColumn.accessor("icc", {
        id: "networkCredentialTypes",
        header: "Network Credential Types",
        cell: row => <SimCardNetworkCredentialTypesCell icc={row.getValue()} />,
    }),
    simCardColumn.accessor("icc", {
        id: "ipAddress",
        header: "IP",
        cell: row => (
            <SimNetworkStateCell
                icc={row.getValue()}
                property={networkState => <PlaceholderText text={networkState?.ip.ip} />}
            />
        ),
    }),
    simCardColumn.accessor("icc", {
        id: "country",
        header: "Country",
        cell: row => (
            <SimNetworkStateCell
                icc={row.getValue()}
                property={networkState => (
                    <CountryInfo country={getCountry(networkState?.network?.country) as Country} withFlag />
                )}
            />
        ),
    }),
    simCardColumn.accessor("icc", {
        id: "network",
        header: "PLMN",
        cell: row => (
            <SimNetworkStateCell
                icc={row.getValue()}
                property={networkState => <NetworkInfo plmn={networkState?.network?.plmn} />}
            />
        ),
    }),
    simCardColumn.accessor("icc", {
        id: "rat",
        header: "Radio Technology",
        cell: row => (
            <SimNetworkStateCell
                icc={row.getValue()}
                property={networkState => <PlaceholderText text={networkState?.radioTechnology?.radioTechnology} />}
            />
        ),
    }),
    simCardColumn.accessor("icc", {
        id: "circuitSwitching",
        header: "Circuit Switching",
        cell: row => (
            <SimNetworkStateCell
                icc={row.getValue()}
                property={networkState => (
                    <LocationUpdateState
                        type={"CIRCUIT_SWITCHING"}
                        status={networkState?.circuitSwitchingLocationUpdate.status}
                    />
                )}
            />
        ),
    }),
    simCardColumn.accessor("icc", {
        id: "packetSwitching",
        header: "Packet Switching",
        cell: row => (
            <SimNetworkStateCell
                icc={row.getValue()}
                property={networkState => (
                    <LocationUpdateState
                        type={"PACKAGE_SWITCHING"}
                        status={networkState?.packetSwitchingLocationUpdate.status}
                    />
                )}
            />
        ),
    }),
];

const SimCardListPage = () => {
    const { simCardApi } = useApi();
    const { navigate } = usePageNavigation<SimCard>({ route: value => `/simcards/${value.icc}` });
    const [chosenAction, setChosenAction] = useState<SimActionType | null>(null);
    const [selectedIccs, setSelectedIccs] = useState<string[]>([]);
    const countries = useMemo(() => getCountries(), []);
    const { pageState, setPageState, resetPageState } = useUrlPagination({
        pageIndex: 0,
        pageSize: 20,
    });
    const search = useSimCardSearch({ onChange: resetPageState });
    const { state } = search;

    const actionFilter: SimCardActionRequestFilter = {
        search: state?.search?.value || undefined,
        msisdnSearch: state?.msisdnSearch?.value || undefined,
        iccLowerBound: state?.iccRange?.from || undefined,
        iccUpperBound: state?.iccRange?.to || undefined,
        networkState: state?.simCardState?.value || undefined,
        iccSet: selectedIccs.length ? new Set<string>(selectedIccs) : undefined,
        customerId: state?.customer?.value?.id,
        billingGroupIdSet: (state?.billingGroup?.value && new Set([state?.billingGroup.value.id])) || undefined,
        consumptionState: state?.consumptionState?.value?.value || undefined,
        country: state?.country?.value?.iso2 || undefined,
        simCapability: state?.simCapability?.value || undefined,
        networkCredentialTypeId: state?.networkCredentialType?.value?.id || undefined,
        simConfigurationId: state?.simConfiguration?.value?.id || undefined,
        plmnSearch: state?.plmnSearch?.value || undefined,
        isMultiImsi: mapBooleanFilter(state?.isMultiImsi?.value),
        usagePackageTypeId: state?.usagePackageType?.value?.id || undefined,
    };

    const { data: simCards, isLoading } = useQuery(["simcards", pageState, state], () => {
        return simCardApi.getSimCards({
            pageNumber: pageState.pageIndex,
            pageSize: pageState.pageSize,
            search: state?.search?.value || undefined,
            msisdnSearch: state?.msisdnSearch?.value || undefined,
            billingGroupIdSet: state?.billingGroup?.value?.id
                ? new Set<string>([state?.billingGroup?.value?.id])
                : undefined,
            customerId: state?.customer?.value?.id,
            iccLowerBound: state?.iccRange?.from || undefined,
            iccUpperBound: state?.iccRange?.to || undefined,
            networkState: state?.simCardState?.value || undefined,
            consumptionState: state?.consumptionState?.value?.value || undefined,
            country: state?.country?.value?.iso2 || undefined,
            simCapability: state?.simCapability?.value || undefined,
            networkCredentialTypeSearch: state?.networkCredentialType?.value?.id || undefined,
            simConfigurationSearch: state?.simConfiguration?.value?.id || undefined,
            plmnSearch: state?.plmnSearch?.value || undefined,
            isMultiImsi: mapBooleanFilter(state?.isMultiImsi?.value),
            usagePackageTypeSearch: state?.usagePackageType?.value?.id || undefined,
        });
    });

    return (
        <>
            <PageLayout title={<PageTitle title="Inventory" />}>
                <VStack w={"100%"} gap={2}>
                    <SearchBar
                        defaultFilterInputId={"search"}
                        useSearch={search}
                        actions={{
                            enabled: selectedIccs.length > 0 || filterHasValues(actionFilter),
                            entries: [
                                {
                                    id: "activate",
                                    label: "Activate",
                                    onClick: () => setChosenAction("activate"),
                                },
                                {
                                    id: "Suspend",
                                    label: "Suspend",
                                    onClick: () => setChosenAction("suspend"),
                                },
                                {
                                    id: "reset",
                                    label: "Reset",
                                    onClick: () => setChosenAction("reset"),
                                },
                                {
                                    id: "set-imei-lock-state",
                                    label: "Set IMEI Lock Behaviour",
                                    onClick: () => setChosenAction("imei-lock-state"),
                                },
                                {
                                    id: "send-sms",
                                    label: "Send SMS",
                                    onClick: () => setChosenAction("send-sms"),
                                },
                                {
                                    id: "assign-usage-package",
                                    label: "Assign Bundle",
                                    onClick: () => setChosenAction("assign-usage-package"),
                                },
                                {
                                    id: "revoke-usage-package",
                                    label: "Revoke Bundle",
                                    onClick: () => setChosenAction("revoke-usage-package"),
                                },
                                {
                                    id: "move-sim-cards",
                                    label: "Move SIM Cards",
                                    onClick: () => setChosenAction("move-sim-cards"),
                                },
                                {
                                    id: "set-overage-state",
                                    label: "Set Overage State",
                                    onClick: () => setChosenAction("set-overage-state"),
                                },
                            ],
                        }}
                        filterInputs={[
                            {
                                id: "search",
                                menuLabel: "Search",
                                inputComponent: (
                                    <SearchSingleInput
                                        registration={search.registerInput({ id: "search" })}
                                        placeholder={"Quick find..."}
                                        tooltip={<SimCardQuickFindTooltip />}
                                    />
                                ),
                            },
                            {
                                id: "iccRange",
                                menuLabel: "ICC Range",
                                inputComponent: (
                                    <SearchRangeInput
                                        type={"number"}
                                        registration={search.registerRangeInput({ id: "iccRange" })}
                                        fromPlaceholder={"From ICC"}
                                        toPlaceholder={"To ICC"}
                                    />
                                ),
                            },
                            {
                                id: "msisdn",
                                menuLabel: "MSISDN",
                                inputComponent: (
                                    <SearchSingleInput
                                        registration={search.registerInput({ id: "msisdnSearch" })}
                                        placeholder={"Search for MSISDN"}
                                    />
                                ),
                            },
                            {
                                id: "customerId",
                                menuLabel: "Customer",
                                inputComponent: (
                                    <SearchSelectCustomerInput
                                        registration={search.registerInput({ id: "customer" })}
                                    />
                                ),
                            },
                            {
                                id: "billingGroupId",
                                menuLabel: "Billing Group",
                                inputComponent: (
                                    <SearchSelectBillingGroupInput
                                        registration={search.registerInput({ id: "billingGroup" })}
                                        selectedCustomer={state.customer?.value}
                                    />
                                ),
                            },
                            {
                                id: "simCardState",
                                menuLabel: "SIM Card State",
                                inputComponent: (
                                    <SearchSelectSimCardNetworkStateInput
                                        registration={search.registerInput({ id: "simCardState" })}
                                    />
                                ),
                            },
                            {
                                id: "consumptionState",
                                menuLabel: "Usage Activity",
                                inputComponent: (
                                    <SearchSelectConsumptionState
                                        registration={search.registerInput({ id: "consumptionState" })}
                                    />
                                ),
                            },
                            {
                                id: "simCapability",
                                menuLabel: "SIM Capability",
                                inputComponent: (
                                    <SearchSelectInput
                                        registration={search.registerInput({ id: "simCapability" })}
                                        items={Object.values(SimCardCapabilityName)}
                                        autocompleteAbleMapping={value => SimCardCapabilityNameLabels[value]}
                                        displayMapping={value => SimCardCapabilityNameLabels[value]}
                                    />
                                ),
                            },
                            {
                                id: "networkCredentialType",
                                menuLabel: "Network Credential Type",
                                inputComponent: (
                                    <SearchSelectNetworkCredentialTypeInput
                                        registration={search.registerInput({ id: "networkCredentialType" })}
                                    />
                                ),
                            },
                            {
                                id: "country",
                                menuLabel: "Country",
                                inputComponent: (
                                    <SearchSelectInput
                                        registration={search.registerInput({ id: "country" })}
                                        items={countries}
                                        autocompleteAbleMapping={value => `${value.name} (${value.iso2})`}
                                        displayMapping={value => <CountryInfo country={value} />}
                                    />
                                ),
                            },
                            {
                                id: "simConfiguration",
                                menuLabel: "SIM Configuration",
                                inputComponent: (
                                    <SearchSelectSimConfigurationInput
                                        registration={search.registerInput({ id: "simConfiguration" })}
                                    />
                                ),
                            },
                            {
                                id: "plmnSearch",
                                menuLabel: "PLMN Search",
                                inputComponent: (
                                    <SearchSingleInput
                                        registration={search.registerInput({ id: "plmnSearch" })}
                                        placeholder={"Search for PLMN"}
                                    />
                                ),
                            },
                            {
                                id: "isMultiImsi",
                                menuLabel: "Multi IMSI",
                                inputComponent: (
                                    <SearchSelectInput
                                        registration={search.registerInput({ id: "isMultiImsi" })}
                                        items={[true, false]}
                                        autocompleteAbleMapping={isMultiImsiFilterLabel}
                                        displayMapping={isMultiImsiFilterLabel}
                                    />
                                ),
                            },
                            {
                                id: "usagePackageType",
                                menuLabel: "Bundle",
                                inputComponent: (
                                    <SearchSelectUsagePackageTypeInput
                                        registration={search.registerInput({ id: "usagePackageType" })}
                                    />
                                ),
                            },
                        ]}
                    />
                    <CobiraTable
                        sizing={"fit-page"}
                        columns={COLUMNS}
                        data={simCards?.content || []}
                        isLoading={isLoading}
                        withPagination={{
                            pageIndex: pageState.pageIndex,
                            pageSize: pageState.pageSize,
                            onPaginationChange: setPageState,
                            totalRowCount: simCards?.pageProperties?.totalElements || 0,
                            totalPageCount: simCards?.pageProperties?.totalPages || 0,
                        }}
                        withRowClick={{
                            enableHoverStyle: true,
                            onRowClicked: navigate,
                        }}
                        withRowSelection={{
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            rowId: row => row.icc!,
                            onChange: setSelectedIccs,
                        }}
                        withColumnSelection={{
                            tableKey: "simcard-table",
                            maxColumnCount: 8,
                        }}
                    />
                </VStack>
            </PageLayout>
            <SimCardActionModals
                actionType={chosenAction}
                filter={actionFilter}
                onClose={() => setChosenAction(null)}
            />
        </>
    );
};

export default SimCardListPage;
